import {
  AnalyticsData,
  OptionalTraits,
  track as TrackAnalytics,
  updateAnalyticsData as UpdateAnalytics,
} from './analytics';

/**
 * TODO
 * Might make sense to provide each project with it's tracking implementation using a context perhaps
 */

export interface TrackMessage {
  type: 'track';
  eventName: string;
  traits?: OptionalTraits;
}

export interface UpdateAnalyticsMessage {
  type: 'update';
  data: AnalyticsData;
}

export const listenToChildAnalytics = (): void => {
  window.addEventListener('message', (event) => {
    const data = event.data as (UpdateAnalyticsMessage | TrackMessage) & { source: string | undefined | null };
    if (data.source !== 'internalTracking') {
      return;
    }
    switch (data.type) {
      case 'track':
        TrackAnalytics(data.eventName, data.traits);
        break;
      case 'update':
        UpdateAnalytics(data.data);
        break;
      default:
        assertNever(data);
    }
  });
};

export function assertNever(value: never, noThrow?: boolean): never {
  if (noThrow) {
    return value;
  }

  throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
}

export const updateAnalyticsData = (data: AnalyticsData) => {
  window.parent.postMessage({ source: 'internalTracking', type: 'update', data: data }, '*');
};

export const track = (eventName: string, traits?: OptionalTraits) => {
  window.parent.postMessage({ source: 'internalTracking', type: 'track', eventName: eventName, traits: traits }, '*');
};
