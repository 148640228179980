import { Palette } from '../../Types';

const COLOR_PALETTES: Palette[] = [
  {
    name: 'Marine',
    colors: ['#4D7FDF', '#000F81'],
    light: '#4D7FDF',
    dark: '#000F81',
  },
  {
    name: 'Green',
    colors: ['#67C64F', '#0F6800'],
    light: '#67C64F',
    dark: '#0F6800',
  },
  {
    name: 'Aqua',
    colors: ['#57D9F0', '#005898'],
    light: '#57D9F0',
    dark: '#005898',
  },
  {
    name: 'Orange',
    colors: ['#FFA351', '#D94A00'],
    light: '#FFA351',
    dark: '#D94A00',
  },
  {
    name: 'Pink',
    colors: ['#FFB8F3', '#B10050'],
    light: '#FFB8F3',
    dark: '#B10050',
  },
  {
    name: 'Purple',
    colors: ['#C39EFF', '#620089'],
    light: '#C39EFF',
    dark: '#620089',
  },
  {
    name: 'Red',
    colors: ['#FF2F43', '#9E002C'],
    light: '#FF2F43',
    dark: '#9E002C',
  },
  {
    name: 'Yellow',
    colors: ['#FFD342', '#C88E00'],
    light: '#FFD342',
    dark: '#C88E00',
  },
];

export default COLOR_PALETTES;
