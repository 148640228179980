import { useEffect, useState } from 'react';
import fscreen from 'fscreen';

const requestFullScreen = () => {
  try {
    fscreen.requestFullscreen(document.body);
  } catch (e) {
    console.log(e);
  }
};

export const useFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(fscreen.fullscreenElement === null);
  useEffect(() => {
    const fullscreenListener = () => {
      setIsFullscreen(fscreen.fullscreenElement !== null && fscreen.fullscreenEnabled);
    };
    if (!isIOS()) {
      document.addEventListener('click', requestFullScreen, { once: true });
      fscreen.addEventListener('fullscreenchange', fullscreenListener);
    }
    return () => {
      fscreen.removeEventListener('fullscreenchange', fullscreenListener);
      document.removeEventListener('click', requestFullScreen);
    };
  }, [setIsFullscreen]);

  const toggleFullscreen = () => {
    if (fscreen.fullscreenElement === null) {
      if (fscreen.fullscreenEnabled) {
        requestFullScreen();
      }
    } else {
      try {
        fscreen.exitFullscreen();
      } catch (e) {
        console.log(e);
      }
    }
  };

  return { isFullScreen: isFullscreen, toggleFullScreen: toggleFullscreen };
};

export const isIOS = () =>
  ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
