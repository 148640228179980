import React, { CSSProperties, useEffect } from 'react';
import './ScoreSummary.css';
import { useGameObject } from '../../store/GameContext';
import { Rat } from '../../components/Rat';
import { Guess, GuessReason } from '@magicyard/blanksy-game/src/Types';
import { assertNever } from '@magicyard/utils/typeUtils';
import blue from '../../assets/point-markers/blue.webp';
import green from '../../assets/point-markers/green.webp';
import pink from '../../assets/point-markers/pink.webp';
import pinkThin from '../../assets/point-markers/pink-thin.webp';
import greenThin from '../../assets/point-markers/green-thin.webp';
import grey from '../../assets/point-markers/grey.webp';
import greyThin from '../../assets/point-markers/grey-thin.webp';
import greyThinnest from '../../assets/point-markers/grey-thinnest.webp';
import greyLarge from '../../assets/point-markers/grey-large.webp';
import { Title } from '../Title';
import { MAX_ROUNDS } from '@magicyard/blanksy-game/src/Config';
import { objectKeys } from '@magicyard/utils';

enum Steps {
  Empty,
  DRAWER,
  GUESS,
  TOP_VOTED,
  SUM,
}

interface GroupedGuess {
  count: number;
  step: number;
}

type GuessGroups = GuessReason | 'TOP_VOTED';

const scoreForGuessType: Record<GuessGroups, number> = {
  GUESS_FIRST: 10,
  GUESS_REGULAR: 5,
  DRAWER_FIRST: 10,
  DRAWER_REGULAR: 2,
  TOP_VOTED: 12,
};

const createGuessGroup = (): Record<GuessGroups, GroupedGuess> => ({
  GUESS_FIRST: { count: 0, step: Steps.GUESS },
  GUESS_REGULAR: { count: 0, step: Steps.GUESS },
  DRAWER_FIRST: { count: 0, step: Steps.DRAWER },
  DRAWER_REGULAR: { count: 0, step: Steps.DRAWER },
  TOP_VOTED: { count: 0, step: Steps.TOP_VOTED },
});

type Grouped = Record<string, Record<GuessGroups, GroupedGuess>>;

export const ScoreSummary = () => {
  const { G, ctx } = useGameObject();
  const [step, setStep] = React.useState<Steps>(Steps.Empty);

  useEffect(() => {
    setTimeout(() => setStep(Steps.DRAWER), 3000);
    setTimeout(() => setStep(Steps.GUESS), 6000);
    setTimeout(() => setStep(Steps.TOP_VOTED), 9000);
    setTimeout(() => setStep(Steps.SUM), 12000);
  }, []);

  const groupedGuessesReducer = (acc: Grouped, guess: Guess, index: number) => {
    acc[guess.playerID][guess.reason].count += 1;
    return acc;
  };

  const addTopVoted = (round: number, groupedGuesses: Grouped) => {
    G.players.forEach((player) => {
      if (G.topVoted[round].includes(player.id)) {
        groupedGuesses[player.id]['TOP_VOTED'].count = 1;
      }
    });
  };
  const createInitial = (): Grouped =>
    G.players.reduce<Grouped>((acc, p) => {
      acc[p.id] = createGuessGroup();
      return acc;
    }, {});

  const groupedGuesses =
    G.guesses[G.currentRoundNumber]?.reduce(groupedGuessesReducer, createInitial()) ?? createInitial();
  addTopVoted(G.currentRoundNumber, groupedGuesses);

  const historicGroupedGuesses = objectKeys(G.guesses)
    .filter((round) => round < G.currentRoundNumber)
    ?.map((round) => {
      const group = G.guesses[round]?.reduce(groupedGuessesReducer, createInitial());
      addTopVoted(+round, group);
      return group;
    });

  const pointsForPlayerForStep = G.players.reduce<Record<string, number>>((acc, player) => {
    acc[player.id] = objectKeys(groupedGuesses[player.id]).reduce((acc, reason) => {
      if (groupedGuesses[player.id][reason].step === step) {
        acc += groupedGuesses[player.id][reason].count * scoreForGuessType[reason];
      }

      if (step === Steps.SUM) {
        acc = G.score[+player.id];
      }

      return acc;
    }, 0);
    return acc;
  }, {});
  return (
    <div className={'score-summary_root'}>
      <div
        className={`score-summary_title-container ${
          step >= Steps.DRAWER ? 'score-summary_title-container-with-emoji' : ''
        }`}
      >
        <Title title={'Scoreboard'} />
        <div className={'score-summary_subtitle'}>
          round {G.currentRoundNumber + 1} of {MAX_ROUNDS}
        </div>
      </div>
      <Rat variant={'announcer'} absolutePosition={{ left: 0, bottom: 100 }} mirror={true} />
      {iconForStep(step)}
      {G.players.map((player, i) => {
        return (
          <div className={'score-summary_col-container'} key={i}>
            {pointsForPlayerForStep[player.id] === 0 ? undefined : (
              <div style={{ color: stepToColor(step) }}>
                {step === Steps.SUM ? '' : '+'} {pointsForPlayerForStep[player.id]}
              </div>
            )}
            {sortAndFilterGroup(groupedGuesses, player.id)
              .filter((group) => groupedGuesses[player.id][group].step <= step)
              .map((group, index, sorted) => {
                const toReturn = [];
                for (let i = 0; i < groupedGuesses[player.id][group].count; i++) {
                  const lineWithMargin = guessReasonToLine(
                    group,
                    i + 1 < groupedGuesses[player.id][group].count ? group : sorted[index + 1]
                  );
                  toReturn.push(
                    <div
                      key={group + '' + i}
                      style={{
                        backgroundImage: `url(${lineWithMargin.image})`,
                        ...lineWithMargin.style,
                      }}
                      className={`score-summary_mark ${(index + i) % 2 === 0 ? 'score-summary_mark-mirror' : ''}`}
                    />
                  );
                }
                return toReturn;
              })}
            {historicGroupedGuesses?.map((historicGroup) => {
              return sortAndFilterGroup(historicGroup, player.id).map((group, index, sorted) => {
                const toReturn = [];
                for (let i = 0; i < historicGroup[player.id][group].count; i++) {
                  const lineWithMargin = historicGuessReasonToLine(
                    group,
                    i + 1 < historicGroup[player.id][group].count ? group : sorted[index + 1]
                  );
                  const marginOverride = index === 0 && i === 0 ? { marginTop: -60 } : undefined;
                  toReturn.push(
                    <div
                      key={index + '' + i}
                      style={{
                        backgroundImage: `url(${lineWithMargin.image})`,
                        ...lineWithMargin.style,
                        ...marginOverride,
                      }}
                      className={`score-summary_historic ${(index + i) % 2 === 0 ? 'score-summary_mark-mirror' : ''}`}
                    />
                  );
                }
                return toReturn;
              });
            })}
            <div
              className={'score-summary_player-avatar'}
              style={{ backgroundImage: `url(${player.avatarUrl})` }}
              key={'avatar' + player.id}
            />
            {player.name}
          </div>
        );
      })}
    </div>
  );
};

const sortAndFilterGroup = (groupedItem: Grouped, playerId: string) =>
  objectKeys(groupedItem[playerId])
    .sort((a, b) => groupedItem[playerId][b].step - groupedItem[playerId][a].step)
    .filter((group) => groupedItem[playerId][group].count > 0);

const guessReasonToLine = (
  reason: GuessGroups,
  lastLine: GuessGroups | undefined
): { image: string; style: CSSProperties } => {
  switch (reason) {
    case 'GUESS_FIRST':
      return { style: { ...lastGuessReasonToMargin(lastLine), ...guessReasonToMargin(reason) }, image: green };
    case 'GUESS_REGULAR':
      return { style: { ...lastGuessReasonToMargin(lastLine), ...guessReasonToMargin(reason) }, image: greenThin };
    case 'DRAWER_FIRST':
      return { style: { ...lastGuessReasonToMargin(lastLine), ...guessReasonToMargin(reason) }, image: pink };
    case 'DRAWER_REGULAR':
      return { style: { ...lastGuessReasonToMargin(lastLine), ...guessReasonToMargin(reason) }, image: pinkThin };
    case 'TOP_VOTED':
      return { style: { ...lastGuessReasonToMargin(lastLine) }, image: blue };
    default:
      assertNever(reason);
  }
};

const iconForStep = (step: Steps) => {
  const icons = [];
  if (step >= Steps.DRAWER) {
    icons.push(
      <div className={'score-summary_emoji-art-container'} style={{ color: stepToColor(Steps.DRAWER) }}>
        <div className={'score-summary_emoji-art'} />
        Drawing
      </div>
    );
  }

  if (step >= Steps.GUESS) {
    icons.push(
      <div className={'score-summary_emoji-nerd-container'} style={{ color: stepToColor(Steps.GUESS) }}>
        <div className={'score-summary_emoji-nerd'} />
        Guessing
      </div>
    );
  }

  if (step >= Steps.TOP_VOTED) {
    icons.push(
      <div className={'score-summary_emoji-laugh-container'} style={{ color: stepToColor(Steps.TOP_VOTED) }}>
        <div className={'score-summary_emoji-laugh'} />
        Funniest
      </div>
    );
  }

  return icons;
};

const guessReasonToMargin = (reason: GuessGroups) => {
  switch (reason) {
    case 'GUESS_FIRST':
      return { marginTop: -15 };
    case 'GUESS_REGULAR':
      return { marginTop: -25 };
    case 'DRAWER_FIRST':
      return { marginTop: -20 };
    case 'DRAWER_REGULAR':
      return { marginTop: -25 };
    case 'TOP_VOTED':
      return { marginTop: 0 };
    default:
      assertNever(reason);
  }
};

const lastGuessReasonToMargin = (lastLine: GuessGroups | undefined) => {
  if (lastLine === undefined) {
    return undefined;
  }
  switch (lastLine) {
    case 'GUESS_FIRST':
      return { marginBottom: -25 };
    case 'GUESS_REGULAR':
      return { marginBottom: -28 };
    case 'DRAWER_FIRST':
      return { marginBottom: -25 };
    case 'DRAWER_REGULAR':
      return { marginBottom: -33 };
    case 'TOP_VOTED':
      return { marginBottom: -40 };
    default:
      assertNever(lastLine);
  }
};

const historicGuessReasonToLine = (reason: GuessGroups, lastLine: GuessGroups | undefined) => {
  switch (reason) {
    case 'GUESS_FIRST':
      return { style: { ...lastGuessReasonToMargin(lastLine), ...guessReasonToMargin(reason) }, image: grey };
    case 'GUESS_REGULAR':
      return { style: { ...lastGuessReasonToMargin(lastLine), ...guessReasonToMargin(reason) }, image: greyThin };
    case 'DRAWER_FIRST':
      return { style: { ...lastGuessReasonToMargin(lastLine), ...guessReasonToMargin(reason) }, image: grey };
    case 'DRAWER_REGULAR':
      return { style: { ...lastGuessReasonToMargin(lastLine), ...guessReasonToMargin(reason) }, image: greyThinnest };
    case 'TOP_VOTED':
      return { style: { ...lastGuessReasonToMargin(lastLine), ...guessReasonToMargin(reason) }, image: greyLarge };
    default:
      assertNever(reason);
  }
};

const stepToColor = (step: Steps) => {
  switch (step) {
    case Steps.Empty:
      return '';
    case Steps.DRAWER:
      return '#b461ac';
    case Steps.GUESS:
      return '#7a9c32';
    case Steps.TOP_VOTED:
      return '#54b9b9';
    case Steps.SUM:
      return 'black';
    default:
      assertNever(step);
  }
};
