import type { BoardProps } from 'boardgame.io/react';
import BoardUtil from './utils/board.util';
import MovesUtil from './utils/moves.util';
import { IPlayer, G, BoardPropTypes, CtxWithApi, WordForPlayer, TapeColor, tapeColors, ColorPalette } from './Types';
import getRandomPlayingCards, { PlayingCard } from './utils/assets/playingCards';
import COLOR_PALETTES from './utils/assets/color-palettes';
import { Game, PhaseConfig, PlayerID } from 'boardgame.io';
import { MAX_ROUNDS } from './Config';
import { ActivePlayers } from 'boardgame.io/core';
import movesUtil from './utils/moves.util';
import { randomChoice } from '@magicyard/utils/numberUtils';
import { objectKeys } from '@magicyard/utils';

export enum Phases {
  Sync = 'Sync',
  Explainer = 'Explainer',
  Initial = 'Initial',
  First = 'firstStage',
  Second = 'secondStage',
  Third = 'thirdStage',
  Fourth = 'fourthStage',
  EndRound = 'endRound',
  GameEnd = 'gameEnd',
}

interface SetupData {
  players?: IPlayer[];
}

// Must be extended with moves and playerId to fool type system
export type BoardPropsExtended = BoardProps<BoardPropTypes> & {
  playerID: PlayerID;
  moves: { [key in keyof typeof MovesUtil]: (...arg: any) => void };
};

const setup = (ctx: CtxWithApi, setupData: SetupData): G => {
  const defaultPlayers = Array(ctx.numPlayers - 1);

  const initPlayers = setupData?.players || defaultPlayers.fill(null);

  const players: IPlayer[] = initPlayers.map((player, index) => {
    return {
      id: index.toString(),
      name: player?.name || `Player ${index}`,
      avatarUrl: player?.avatarUrl || `https://i.pravatar.cc/300?img=${index + 5}`,
      active: true,
    };
  });

  const playersLength = players.length;
  const zeroArrayPlayersLength = Array<number>(playersLength).fill(0);
  const score = [...zeroArrayPlayersLength];

  const wordForPlayer = zeroArrayPlayersLength.reduce<WordForPlayer>((acc, _, idx) => {
    acc[idx] = undefined as any; // CyclePlayingItems is not optional though in this state it is, consider future improvement on this
    return acc;
  }, {});

  const shuffledCanvas: TapeColor[] = ctx.random.Shuffle([...tapeColors]);
  const shuffledPalettes = ctx.random.Shuffle([...COLOR_PALETTES]);

  const colorPalettes = zeroArrayPlayersLength.reduce<{
    [key: number]: ColorPalette;
  }>((acc, _, idx) => {
    acc[idx] = {
      palette: shuffledPalettes[idx],
      playerID: '' + idx,
      canvas: shuffledCanvas[idx],
    };
    return acc;
  }, {});

  const cardSentences = getRandomPlayingCards(MAX_ROUNDS * 2).map<PlayingCard>((pCard) => {
    return {
      ...pCard,
      words: ctx.random.Shuffle([...pCard.words]),
    };
  });

  // Game State
  return {
    didGameEnd: false,
    isTransition: true,
    phaseStartTime: Date.now(),
    currentRoundNumber: 0,
    players,
    score,
    cardSentences,
    colorPalettes,
    wordForPlayer: wordForPlayer,
    guesses: { [0]: [] },
    ratingVoters: {},
    roundImageLogs: [],
    roundDrawings: { [0]: {} },
    scoreDeltas: zeroArrayPlayersLength,
    topVoted: { [0]: [] },
    shouldForceSubmit: false,
    shouldClearDrawingStorage: players.reduce<Record<string, boolean>>((acc, p) => {
      acc[p.id] = true;
      return acc;
    }, {}),
  };
};

const basePhase = {
  turn: {
    activePlayers: ActivePlayers.ALL,
  },
  moves: {
    timesUp: movesUtil.timesUp,
    forceEndPhase: movesUtil.forceEndPhase,
    transitionTimeUp: movesUtil.transitionTimeUp,
  },
  onEnd: (G: G) => {
    G.isTransition = true;
    G.shouldForceSubmit = false;
    G.phaseStartTime = Date.now();
  },
};

const AllPhases: Record<Phases, PhaseConfig<G, CtxWithApi>> = {
  [Phases.Sync]: {
    ...basePhase,
    moves: {
      ...basePhase.moves,
      endSync: MovesUtil.endSync,
    },
    next: Phases.Explainer,
    start: true,
  },
  [Phases.Explainer]: {
    ...basePhase,
    moves: {
      ...basePhase.moves,
      skipPhase: MovesUtil.skipPhase,
    },
    next: Phases.Initial,
  },
  [Phases.Initial]: {
    next: Phases.First,
    ...basePhase,
    onEnd: (G) => {
      basePhase.onEnd(G);
    },
    endIf: (G: G) => Object.values(G.wordForPlayer).every((x) => x !== undefined),
    moves: {
      s00_confirmCycleCardWord: MovesUtil.s00_confirmCycleCardWord,
      ...basePhase.moves,
    },
  },
  [Phases.First]: {
    next: Phases.Second,
    ...basePhase,
    onEnd: (G, ctx) => {
      basePhase.onEnd(G);
      G.shouldClearDrawingStorage = G.players.reduce<Record<string, boolean>>((acc, p) => {
        acc[p.id] = true;
        return acc;
      }, {});
    },
    endIf: (G: G, ctx: CtxWithApi) => {
      return objectKeys(G.roundDrawings[G.currentRoundNumber]).length === ctx.numPlayers - 1;
    },
    moves: {
      s01_submitDrawing: MovesUtil.s01_submitDrawing,
      confirmClearStorage: MovesUtil.confirmClearStorage,
      ...basePhase.moves,
    },
  },
  [Phases.Second]: {
    next: Phases.Third,
    ...basePhase,
    onEnd: (G, ctx) => {
      basePhase.onEnd(G);
      BoardUtil.calculateScore(G, ctx);
    },
    endIf: (G) => {
      const totalCorrectGuesses = G.guesses[G.currentRoundNumber].length;
      return totalCorrectGuesses === G.players.length * (G.players.length - 1) * 2;
    },
    turn: {
      activePlayers: ActivePlayers.ALL,
    },
    moves: {
      s02_guessWord: MovesUtil.s02_guessWord,
      ...basePhase.moves,
    },
  },
  [Phases.Third]: {
    next: Phases.Fourth,
    ...basePhase,
    moves: {
      s03_submitVote: MovesUtil.s03_submitVote,
      ...basePhase.moves,
    },
    onEnd: (G, ctx) => {
      basePhase.onEnd(G);

      // No scoring for one player
      if (G.players.length > 1) {
        G.players
          .filter((player) => G.ratingVoters[player.id] === undefined)
          .forEach((player) => {
            const otherPlayers = G.players.filter((p) => p.id !== player.id);
            G.ratingVoters[player.id] = randomChoice(otherPlayers).id;
          });
      }
      BoardUtil.calculateVotingScore(G, ctx);
    },
  },
  [Phases.Fourth]: {
    next: Phases.EndRound,
    ...basePhase,
  },
  [Phases.EndRound]: {
    next: (G) => {
      if (!G.didGameEnd) {
        return Phases.Initial;
      }
      return Phases.GameEnd;
    },
    ...basePhase,
    onEnd: (G, ctx) => {
      basePhase.onEnd(G);
      MovesUtil.closeRound(G, ctx);
    },
  },
  [Phases.GameEnd]: {
    ...basePhase,
  },
};

export const GAME_ID = 'blanksy';

export const Blanksy: Game<G, CtxWithApi> = {
  name: GAME_ID,
  setup: setup,
  moves: MovesUtil,
  phases: AllPhases,
};
