import Drawings from '../../components/DrawingsView/Drawings';
import React from 'react';
import { Drawing } from '@magicyard/blanksy-game/src/Types';

export const DrawingsPart = ({
  isPicked,
  drawings,
  showWord,
  showHints,
}: {
  drawings: Drawing[];
  isPicked?: boolean;
  showWord: boolean;
  showHints: boolean;
}) => {
  return (
    <div
      className={'d-flex flex-column flex'}
      style={{
        marginLeft: -100,
        marginTop: 50,
      }}
    >
      <div className={'flex position-relative'}>
        <div className={'text-center d-flex justify-center align-center'}>
          <Drawings
            drawings={drawings}
            showHints={showHints}
            showWord={showWord}
            picked={isPicked === true ? drawings : []}
          />
        </div>
      </div>
    </div>
  );
};
