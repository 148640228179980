import mixpanel from 'mixpanel-browser';
import { listenToChildAnalytics } from './localAnalytics';

let savedData: AnalyticsData = {};
let savedType: 'Display' | 'Controller' | undefined;
let disabled = false;

export interface AnalyticsData {
  yardId?: string | undefined;
  controllerId?: string;
  analyticsSourceName?: string;
  numOfPlayers?: number;
  playerId?: string;
  matchId?: string;
  currentStage?: string;
  currentRound?: number;
  fullName?: string;
  displayId?: string | undefined;
  abTest?: string;
  numDisplaysConnected?: number;
  voiceChatAvailable?: boolean;
  source?: 'Platform' | 'Standalone';
  gameId?: string;
  mode?: string;
}

type Primitive = string | number | boolean | null;
export type TraitValue =
  | Primitive
  | Primitive[]
  | {
      [key: string]: TraitValue;
    };

export type OptionalTraits = {
  [key: string]: TraitValue;
} & {
  [K in keyof AnalyticsData]?: never;
};
export let analyticsCurrentUserId: string | undefined;

interface UserProperties {
  name: string;
}

const createSessionId = () => {
  const s4 = () => {
    // safe client-side function for generating session_id
    // from: https://stackoverflow.com/a/105074
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

// 30min in milliseconds
const SESSION_MAX_DURATION = 1800000;

const mp_session_config = {
  timeout: SESSION_MAX_DURATION,

  getSessionId: () => {
    return createSessionId();
  },

  // set a new session id
  setSessionId: () => {
    mixpanel.register({
      sessionId: mp_session_config.getSessionId(),
    });
  },

  // check for a new session id
  checkSessionId: () => {
    // check #1 do they have a session already?
    if (
      mixpanel.get_property('lastEventAt') === undefined ||
      mixpanel.get_property('sessionId') === undefined ||
      Date.now() - mixpanel.get_property('lastEventAt') > mp_session_config.timeout
    ) {
      mp_session_config.setSessionId();
    }
  },
};

export const initAnalytics = (
  mixpanelApiKey: string | undefined,
  type: 'Display' | 'Controller',
  source: 'Platform' | 'Standalone',
  gameId: string | undefined
): void => {
  // Init only once..
  if (savedType !== undefined) {
    return;
  }
  savedType = type;
  updateAnalyticsData({ source: source, gameId: gameId });
  listenToChildAnalytics();
  if (!mixpanelApiKey) {
    console.info('No Mixpanel API key found, analytics will be disabled');
    disabled = true;
    return;
  }

  mixpanel.init(mixpanelApiKey, {
    debug: process.env.REACT_APP_MGY_ENV !== 'production',
    loaded: (mixpanelInstance) => {
      analyticsCurrentUserId = mixpanelInstance.get_distinct_id();

      // check for a session_id ... if any of the checks fail set a new session id
      mp_session_config.checkSessionId();

      // monkey patch to make sure that mixpanel.track() always checks for a session id
      const originalTrack = mixpanelInstance.track;
      mixpanelInstance.track = (...args) => {
        console.log('session checked');
        mp_session_config.checkSessionId();
        mixpanelInstance.register({ lastEventAt: Date.now() });
        originalTrack.apply(mixpanelInstance, args);
      };
    },
  });
};

export const updateAnalyticsData = (data: AnalyticsData) => {
  savedData = { ...savedData, ...data };
};

const providerTrack = (eventName: string, traits?: OptionalTraits, userId?: string | undefined): void => {
  if (savedType === undefined) {
    console.warn('Track data called before init');
    return;
  }
  const normalizedEventName = `${eventName} - ${savedType}`;
  if (disabled) {
    console.info('Called track while disabled', { eventName: normalizedEventName, traits, userId });
    return;
  }
  if (analyticsCurrentUserId === undefined && userId !== undefined) {
    analyticsCurrentUserId = userId;
  }
  mixpanel.track(normalizedEventName, {
    ...savedData,
    ...traits,
    analyticsSourceName: savedType?.toLowerCase(),
    distinct_id: analyticsCurrentUserId,
  });
};

export const track = (eventName: string, traits?: OptionalTraits) => {
  providerTrack(eventName, { ...traits });
};

export const identify = (userId: string | undefined, userProperties?: UserProperties | undefined) => {
  if (userId === analyticsCurrentUserId && userProperties !== undefined) {
    return;
  }
  if (disabled) {
    console.info('Called identify while disabled', { userId, userProperties });
    return;
  }
  if (userId !== undefined) {
    analyticsCurrentUserId = userId;
  }
  mixpanel.identify(userId);

  if (userProperties !== undefined && (Object.keys(userProperties) as Array<keyof UserProperties>).length > 0) {
    mixpanel.people.set(userProperties);
  }
};
