import React from 'react';
import './Crown.css';

const variantForClass = {
  light: 'crown_light',
  dark: 'crown_dark',
};

export const Crown = ({ className, variant }: { className?: string; variant: keyof typeof variantForClass }) => (
  <div className={`crown_crown ${variantForClass[variant]} ${className ?? ''}`} />
);
