import './AskToUsePhoneOverlay.css';
import React from 'react';
import { Title } from './GameManager/Title';

export const AskToUsePhoneOverlay = () => {
  const [isAnimating, setIsAnimating] = React.useState(false);

  return (
    <div
      className={`use-phone-overlay_root ${isAnimating ? 'use-phone-overlay_root-animated' : ''}`}
      onAnimationEnd={() => setIsAnimating(false)}
      onClick={() => setIsAnimating(true)}
    >
      <Title title={'Use your phone'} />
    </div>
  );
};
