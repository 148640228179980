import { INVALID_MOVE } from 'boardgame.io/core';
import { Phases } from '@magicyard/blanksy-game/src/Game';
import { ColorPalette, CtxWithApi, DrawingBound, G, Stroke, TapeColor, tapeColors } from '../Types';
import COLOR_PALETTES from './assets/color-palettes';
import { MAX_ROUNDS } from '../Config';
import { assertNever } from '@magicyard/utils/typeUtils';

const endSync = (G: G, ctx: CtxWithApi) => {
  console.log('ENDING SYNC PHASE');
  console.log(ctx.playerID);
  // Temp, the screen has the same playerId as the first player
  if (ctx.playerID === '0') {
    ctx.events.endPhase();
    return undefined;
  }
  return INVALID_MOVE;
};

const s00_confirmCycleCardWord = {
  move: (G: G, ctx: CtxWithApi, word: string | null): void => {
    const { playerID } = ctx;
    console.log(`Player ${playerID} submittedWord ${word}`);
    G.wordForPlayer[playerID] = word;
  },
  ignoreStaleStateID: true,
};

const s01_submitDrawing = {
  move: (G: G, ctx: CtxWithApi, strokes: Stroke[], bounds: DrawingBound): void => {
    const { playerID } = ctx;
    const { currentRoundNumber, wordForPlayer, roundDrawings } = G;
    console.log(`Player ${playerID} submittedDrawing`);

    if (!roundDrawings[currentRoundNumber]) {
      roundDrawings[currentRoundNumber] = {};
    }

    roundDrawings[currentRoundNumber][playerID] = {
      playerID,
      strokes: strokes,
      word: wordForPlayer[playerID],
      bounds: bounds,
    };
  },
  ignoreStaleStateID: true,
};

const confirmClearStorage = {
  move: (G: G, ctx: CtxWithApi): void => {
    const { playerID } = ctx;
    G.shouldClearDrawingStorage[playerID] = false;
  },
  ignoreStaleStateID: true,
};

// Simultaneous guessing.
const s02_guessWord = {
  move: (G: G, ctx: CtxWithApi, ...args: string[]): void => {
    const { playerID } = ctx;
    const guess = args[0];
    console.log(`Player ${playerID} guessedWord ${guess}`);
    const sanitizeString = (str: string | undefined | null) => (str ?? '').toLowerCase();

    const didPlayerAlreadyGuessThisWord = G.guesses[G.currentRoundNumber].some(
      (x) => x.word === guess && x.playerID === playerID && (x.reason === 'GUESS_FIRST' || x.reason === 'GUESS_REGULAR')
    );
    if (didPlayerAlreadyGuessThisWord) {
      return;
    }

    const correctGuesses = Object.keys(G.wordForPlayer).reduce<string[]>((acc, currPlayerId) => {
      if (currPlayerId !== playerID && sanitizeString(guess) === sanitizeString(G.wordForPlayer[currPlayerId])) {
        acc.push(currPlayerId);
      }
      return acc;
    }, []);

    if (correctGuesses.length !== 0) {
      const isFirstGuess = !G.guesses[G.currentRoundNumber].some((g) => g.word === guess);
      correctGuesses.forEach((cg) => {
        G.guesses[G.currentRoundNumber].push({
          word: guess,
          playerID,
          reason: isFirstGuess ? 'GUESS_FIRST' : 'GUESS_REGULAR',
          score: isFirstGuess ? 10 : 5,
        });
        G.guesses[G.currentRoundNumber].push({
          word: guess,
          playerID: cg,
          // Calc score for drawer
          reason: isFirstGuess ? 'DRAWER_FIRST' : 'DRAWER_REGULAR',
          score: isFirstGuess ? 10 : 2,
        });
      });
    }
  },
  ignoreStaleStateID: true, // "true" to allow simultaneous moves. AKA: Guess out of order.
};

const s03_submitVote = {
  move: (G: G, ctx: CtxWithApi, ...args: number[]): void => {
    const { playerID } = ctx; // Current player is the drawer.
    const [voteVal] = args;

    console.log(`Player ${playerID} voted for ${voteVal}`);

    G.ratingVoters[playerID] = '' + voteVal;
  },
  ignoreStaleStateID: true,
};

const closeRound = (G: G, ctx: CtxWithApi): void => {
  console.log(`Closing round ${G.currentRoundNumber}`);
  const zeroArrayPlayersLength = Array(G.players.length).fill(0);
  G.wordForPlayer = zeroArrayPlayersLength.reduce((acc, _, idx) => {
    acc[idx] = undefined;
    return acc;
  }, {});

  G.ratingVoters = {};
  const shuffledCanvas: TapeColor[] = ctx.random.Shuffle([...tapeColors]);
  const shuffledPalettes = ctx.random.Shuffle([...COLOR_PALETTES]);
  G.colorPalettes = zeroArrayPlayersLength.reduce<{ [key: string]: ColorPalette }>((acc, _, idx) => {
    acc[idx] = {
      palette: shuffledPalettes[idx],
      playerID: '' + idx,
      canvas: shuffledCanvas[idx],
    };
    return acc;
  }, {});
  if (G.currentRoundNumber < MAX_ROUNDS - 1) {
    G.currentRoundNumber++;
    G.guesses[G.currentRoundNumber] = [];
    G.roundDrawings[G.currentRoundNumber] = {};
    G.topVoted[G.currentRoundNumber] = [];
  } else {
    G.didGameEnd = true;
  }
};

const forceEndPhase = (G: G, ctx: CtxWithApi) => {
  console.log(`Phase ${ctx.phase} was forced to end`);
  if (!isMoveMadeByMasterClient(ctx)) {
    return INVALID_MOVE;
  }
  const phase = ctx.phase as Phases;
  switch (phase) {
    case Phases.Initial:
    case Phases.First:
    case Phases.Second:
    case Phases.Third:
    case Phases.Fourth:
    case Phases.EndRound:
    case Phases.Explainer:
    case Phases.Sync:
      ctx.events.endPhase();
      G.shouldForceSubmit = false;
      break;
    case Phases.GameEnd:
      // Last phase, do nothing
      break;
    default:
      assertNever(phase);
  }

  return undefined;
};

const isMoveMadeByMasterClient = (ctx: CtxWithApi) => +ctx.playerID === ctx.numPlayers - 1;

const timesUp = (G: G, ctx: CtxWithApi) => {
  console.log(`Player ${ctx.playerID} called timesUp on ${ctx.phase}`);

  if (!isMoveMadeByMasterClient(ctx)) {
    return INVALID_MOVE;
  }
  actOnTimesUpForPhase(G, ctx);

  return undefined;
};

const actOnTimesUpForPhase = (G: G, ctx: CtxWithApi) => {
  const phase = ctx.phase as Phases;
  switch (phase) {
    case Phases.Initial:
    case Phases.First:
      G.shouldForceSubmit = true;
      break;
    case Phases.Second:
    case Phases.Third:
    case Phases.Fourth:
    case Phases.EndRound:
    case Phases.Explainer:
    case Phases.Sync:
      ctx.events.endPhase();
      break;
    case Phases.GameEnd:
      // Last phase, do nothing
      break;
    default:
      assertNever(phase);
  }
};

const transitionTimeUp = (G: G, ctx: CtxWithApi) => {
  console.log(`Player ${ctx.playerID} ended the transiton for ${ctx.phase}`);
  if (!isMoveMadeByMasterClient(ctx)) {
    return INVALID_MOVE;
  }
  G.isTransition = false;
  return undefined;
};

const skipPhase = (G: G, ctx: CtxWithApi) => {
  actOnTimesUpForPhase(G, ctx);
};

const MovesUtil = {
  endSync,
  s00_confirmCycleCardWord,
  s01_submitDrawing,
  confirmClearStorage,
  s02_guessWord,
  s03_submitVote,
  closeRound,
  timesUp,
  forceEndPhase,
  transitionTimeUp,
  skipPhase,
};

export default MovesUtil;
