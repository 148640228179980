import { Phases } from '@magicyard/blanksy-game/src/Game';

const MIXPANEL_API_KEY = process.env.REACT_APP_MIXPANEL_API_KEY;

const phaseNames: { [key in Phases]: string } = {
  [Phases.Sync]: 'Sync',
  [Phases.Explainer]: 'Explainer',
  [Phases.Initial]: 'Choose Word',
  [Phases.First]: 'Drawing',
  [Phases.Second]: 'Guessing',
  [Phases.Third]: 'Voting',
  [Phases.Fourth]: 'Vote Summary',
  [Phases.EndRound]: 'Score Table',
  [Phases.GameEnd]: 'Game End',
};
export const getPhaseEventName = (phase: Phases): string => {
  return `${phaseNames[phase]} Phase Loaded`;
};

export const getTransitionPhaseEventName = (phase: Phases): string => {
  return `${phaseNames[phase]} Transition Loaded`;
};
