import React from 'react';
import { useGameObject } from '../../../store/GameContext';
import { StageRoot } from '../StageRoot';
import { Drawing } from '@magicyard/blanksy-game/src/Types';
import { DrawingsPart } from '../DrawingsPart';

const DisplayVoteSummary = () => {
  const { G, ctx } = useGameObject();
  const { roundDrawings, currentRoundNumber, players, topVoted } = G;

  const funniest = topVoted[currentRoundNumber].length === 0 ? ['0'] : topVoted[currentRoundNumber];

  const drawings: Array<Drawing> = players.map((player) => {
    const drawing = roundDrawings[currentRoundNumber][player.id];
    return {
      playerID: player.id,
      strokes: drawing !== undefined ? drawing.strokes : [],
      word: drawing !== undefined ? drawing.word : '',
      bounds: drawing !== undefined ? drawing.bounds : { maxX: 0, maxY: 0 },
    };
  });

  const pickedDrawings = drawings.filter((d) => funniest.includes(d.playerID));
  return (
    <>
      <StageRoot title={'This made you laugh'} sentence={G.cardSentences[G.currentRoundNumber].content}>
        <div className={'flex'} style={{ marginTop: 42 }}>
          <div className={'d-flex flex-row'}>
            <DrawingsPart drawings={pickedDrawings} isPicked={true} showWord={true} showHints={false} />
          </div>
        </div>
      </StageRoot>
    </>
  );
};

export default DisplayVoteSummary;
