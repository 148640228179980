import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { CONFIG, getMultiplayerMode } from '@magicyard/utils';
import { Client } from 'boardgame.io/react';
import { Blanksy, BoardPropsExtended } from '@magicyard/blanksy-game/src/Game';
import Board from './components/Board';
import { useFullscreen } from '@magicyard/shared/platform/hooks/useTryFullscreen';
import useForceScale from '@magicyard/shared/platform/hooks/useForceScale';
import { identify, initAnalytics } from '@magicyard/shared/src/analytics';
import { InitialLoading } from './InitialLoading';
import { Background } from './components/Background/Background';

console.log('Starting with config:\n', { CONFIG });

const getDemoData = (): { matchId: string; displayId: string | null } | null => {
  const params = new URLSearchParams(window.location.search);
  const matchId = params.get('matchID');
  const displayId = params.get('displayId');
  if (matchId === null) {
    return null;
  }

  return { matchId: matchId, displayId: displayId };
};
const demoData = getDemoData();

if (demoData !== null) {
  initAnalytics(CONFIG.MIXPANEL_API_KEY, 'Display', 'Platform', 'blanksy');
  if (demoData.displayId) {
    identify(demoData.displayId);
  }
  const BgioClient = Client({
    loading: () => (
      <Background camPosition={0}>
        <InitialLoading />
      </Background>
    ),
    // loading: <Load
    //@ts-ignore TODO: Remove this and fix resulting type error
    game: Blanksy,
    board: (G: BoardPropsExtended) => <Board {...{ ...G, display: { id: 'demo', code: '1234' } }} />,
    debug: true,
    multiplayer: getMultiplayerMode(),
  });

  const Wrapper = () => {
    useFullscreen();
    useForceScale('#root-blanksy');

    return <BgioClient matchID={demoData.matchId} />;
  };

  ReactDOM.render(
    <React.StrictMode>
      <Wrapper />
    </React.StrictMode>,
    document.getElementById('root-blanksy')
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      {/*@ts-ignore TODO */}
      <App />
    </React.StrictMode>,
    document.getElementById('root-blanksy')
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
