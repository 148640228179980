import React, { useEffect, useState } from 'react';
import { Drawing } from '@magicyard/blanksy-game/src/Types';
import Hint from '../../GameManager/Stages/ActiveDrawing/components/Hint';
import { shuffleArray } from '@magicyard/blanksy-game/src/utils/common.util';
import emojiLaugh from '../../assets/emoji-laugh.webp';
import { DrawingContainer } from './DrawingContainer';
import DrawingAlt from '@magicyard/blanksy-shared/components/DrawingAlt';
import { useGameObject } from '../../store/GameContext';
import { getServerTimePassedMs, useMillisReachedFromServer } from '@magicyard/blanksy-shared/hooks/IntervalHooks';
import { Phases } from '@magicyard/blanksy-game/src/Game';

export type DrawingsProps = {
  drawings: Drawing[];
  picked: Drawing[];
  showWord: boolean;
  showHints: boolean;
};

const Drawings = ({ drawings, showWord, showHints, picked }: DrawingsProps) => {
  const { G, ctx } = useGameObject();
  const [hintLevel, setHintLevel] = useState(0);
  const [shuffledDrawings, setShuffledDrawings] = useState<Array<Drawing>>([]);

  useEffect(() => {
    const shuffled = shuffleArray<Drawing>(drawings);
    setShuffledDrawings(shuffled);
  }, []);

  useMillisReachedFromServer({
    serverTimePassedMs: getServerTimePassedMs(G.phaseStartTime, ctx.phase as Phases, false),
    millisWithCallback: {
      10000: () => setHintLevel(1),
      20000: () => setHintLevel(2),
      30000: () => setHintLevel(3),
      40000: () => setHintLevel(4),
    },
  });

  return (
    <>
      {shuffledDrawings.map((drawing, idx) => (
        <div className={'d-inline-block mx-2 position-relative'} key={drawing.playerID}>
          {picked?.find((x) => x.playerID === drawing.playerID) ? (
            <div className={'position-absolute badge--icon'} style={{ top: 5, right: 5 }}>
              <div
                style={{
                  width: 140,
                  height: 140,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundImage: `url(${emojiLaugh})`,
                }}
              />
            </div>
          ) : null}
          <DrawingContainer amountOfDrawings={shuffledDrawings.length} playerID={drawing.playerID}>
            {drawing ? (
              <DrawingAlt
                strokes={drawing.strokes}
                tapeColor={G.colorPalettes[drawing.playerID].canvas}
                bounds={drawing.bounds}
              />
            ) : null}
          </DrawingContainer>
          {showWord ? (
            <div className={'position-absolute text--sm w-100 d-flex justify-center'} style={{ bottom: 15 }}>
              <div className={'bg-shadow--light px-1'}>{drawing.word}</div>
            </div>
          ) : showHints && drawing.word ? (
            <div className={'position-absolute text--sm w-100 d-flex justify-center'} style={{ bottom: 20, left: 0 }}>
              <div className={'bg-shadow--light px-1'}>
                <Hint word={drawing.word} hintLevel={hintLevel} />
              </div>
            </div>
          ) : null}
        </div>
      ))}
    </>
  );
};

export default Drawings;
