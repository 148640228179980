import React, { CSSProperties } from 'react';
import { LinePath } from './LinePath';
import { DrawingBound, Stroke, TapeColor } from '@magicyard/blanksy-game/src/Types';
import './DrawingAlt.css';
import { assertNever } from '@magicyard/utils/typeUtils';

const DrawingAlt = ({
  strokes,
  tapeColor,
  bounds,
  voteView,
}: {
  strokes: Stroke[] | undefined;
  tapeColor: TapeColor;
  bounds: DrawingBound;
  voteView?: boolean;
}) => {
  return (
    <div style={{ ...tapeColorToDimensions(tapeColor) }}>
      {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
      <svg viewBox={`0,0,${bounds.maxX},${bounds.maxY}`} className={voteView === true ? '' : 'drawing-alt_container'}>
        {strokes !== undefined && Array.isArray(strokes)
          ? strokes.map((l: Stroke, i: number) => <LinePath line={l} key={i} />)
          : 'empty'}
      </svg>
    </div>
  );
};

export default DrawingAlt;

export const tapeColorToDimensions = (tape: TapeColor): CSSProperties => {
  switch (tape) {
    case 'blue':
      return {
        marginTop: '7%',
        width: '86%',
        height: '88.5%',
        marginLeft: '7%',
      };
    case 'orange':
      return {
        marginTop: '7.5%',
        width: '87%',
        height: '89.7%',
        marginLeft: '6%',
      };
    case 'red':
      return {
        marginTop: '6.8%',
        width: '80%',
        height: '88%',
        marginLeft: '11%',
      };
    case 'yellow':
      return {
        marginTop: '8%',
        width: '88%',
        height: '89%',
        marginLeft: '6%',
      };
    case 'green':
      return {
        marginTop: '6.5%',
        width: '82.5%',
        height: '89%',
        marginLeft: '8%',
      };
    default:
      assertNever(tape);
  }
};
