import { useGameObject } from '../../../store/GameContext';

interface ScoreDict {
  prevScore: number;
  playerID: string;
  delta: number;
  score: number;
}

export const GetRoundWinners = () => {
  const { G } = useGameObject();
  const { guesses, scoreDeltas } = G;

  const roundWinners = guesses[G.currentRoundNumber]
    .sort((a, b) => b.score - a.score)
    .reduce<{ [key: string]: number }>((winners, guess) => {
      if (winners[guess.playerID] === undefined) {
        winners[guess.playerID] = scoreDeltas[+guess.playerID];
      }
      winners[guess.playerID] += guess.score;
      return winners;
    }, {});

  const scoreDict: Array<ScoreDict> = G.score.reduce<Array<ScoreDict>>((acc, item, idx) => {
    acc.push({
      prevScore: item - roundWinners[idx],
      playerID: '' + idx,
      delta: roundWinners[idx],
      score: item,
    });
    return acc;
  }, []);

  const sortedPrevLeaderBoard = [...scoreDict].sort((a, b) => b.prevScore - a.prevScore);
  const prevPosLeaderboardDict = sortedPrevLeaderBoard.reduce<{ [key: string]: number }>((acc, item, idx) => {
    acc[item.playerID] = idx;
    return acc;
  }, {});
  const sortedLeaderBoard = [...scoreDict].sort((a, b) => b.score - a.score);

  return { roundWinners, scoreDict, sortedLeaderBoard, prevPosLeaderboardDict };
};
