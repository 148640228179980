import { createDisplay, DisplayDto, getDisplay } from '../lib/api';

export async function getOrCreateDisplay(
  displayId: string | null,
  yardGameId: string | undefined
): Promise<DisplayDto> {
  if (displayId !== null) {
    try {
      console.info('Trying to get live display from API with ID');
      return await getDisplay(displayId);
    } catch (e) {
      console.log('Failed to get display');
    }
  }

  console.info('Creating display...');
  return await createDisplay({
    streamNeeded: false,
    yardGameId: yardGameId,
    id: displayId ?? undefined,
  });
}
