import React from 'react';
import './Rat.css';

const variantForClass = {
  announcer: { shade: 'rat_announcer-shade', rat: 'rat_announcer' },
  spray: { shade: 'rat_spray-shade', rat: 'rat_spray' },
  camera: { shade: 'rat_camera-shade', rat: 'rat_camera' },
};

export const Rat = ({
  absolutePosition,
  variant,
  mirror,
  className,
}: {
  className?: string;
  absolutePosition?: {
    right?: number | string;
    left?: number | string;
    top?: number | string;
    bottom?: number | string;
  };
  variant: keyof typeof variantForClass;
  mirror?: boolean;
}) => (
  <div className={`rat_root ${className ?? ''}`} style={{ ...absolutePosition }}>
    <div className={'rat_container'}>
      <div className={`rat_rat-image ${variantForClass[variant].rat} ${mirror === true ? 'rat_mirror' : ''}`} />
      <div className={`${variantForClass[variant].shade} rat_shade ${mirror === true ? 'rat_mirror' : ''}`} />
    </div>
  </div>
);
