import React from 'react';
import { IPlayer } from '@magicyard/blanksy-game/src/Types';
import { PlayerPart } from './PlayerPart';

export const PlayersPart = ({
  players,
  isVisible,
}: {
  players: IPlayer[];
  isVisible: (playerId: string) => boolean;
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 50 }}>
      {players.map((player, idx) => (
        <PlayerPart player={player} isVisible={isVisible(player.id)} index={idx} key={player.id} />
      ))}
    </div>
  );
};
