import { StateConfigExternal } from './usePlatformDisplayTypes';
import { usePlatformDisplayInternal } from './usePlatformDisplayInternal';

interface Query {
  displayId: string | null;
}

/**
 * @typeParam T - your return type on an action.
 * @typeParam P - the type of the game start args.
 * @param stateConfig - configuration with game event callbacks which update the state
 * @param initialQuery - the initial query parameters
 * @param gameId - the identifier of the game
 */
export function usePlatformDisplay<T, P>(
  stateConfig: StateConfigExternal<T, P>,
  initialQuery: Query,
  gameId: string
): T {
  return usePlatformDisplayInternal<T, P>(
    {
      onLoading: stateConfig.onLoading,
      onGameSelection: () => {
        throw new Error('Missing gameId, did you provide a correct ID?');
      },
      onYardAndDisplay: ({ yard, display, communication }) => {
        return stateConfig.onYardAndDisplay({ yard: yard, display: display, communication: communication });
      },
      onLoadingGame: stateConfig.onLoadingGame,
      onGame: ({ communication, display, onCloseGame, yard, gameStartArgs }) => {
        return stateConfig.onGame({
          communication: communication,
          display: display,
          yard: yard,
          gameStartArgs: gameStartArgs,
          onBack: onCloseGame,
        });
      },
      onQueue: stateConfig.onQueue,
    },
    initialQuery,
    gameId
  );
}
