import React, { useEffect, useState } from 'react';
import './Background.css';
import background from '../../assets/background.jpeg';

const HARD_CODED_SCREEN_WIDTH = 1920;

export interface ChildWithPosition {
  children: React.ReactNode;
  camPosition: number;
  renderPosition: number;
}

export interface BackgroundProps {
  children: React.ReactNode;
  camPosition: number;
  renderPosition?: number;
  onReached?: () => void;
  flexDirection?: 'row' | 'column';
}

export const BACKGROUND_TRANSITION_MS = 1500;

/**
 * @param children
 * @param onReached
 * @param camPosition Where to place the camera
 * @param renderPosition From to render the page, defaults to camPosition. If only this changed (and not camPosition) the old child will be maintained.
 * @constructor
 */
export const Background = ({ children, onReached, camPosition, renderPosition = camPosition }: BackgroundProps) => {
  const [currChild, setCurrChild] = useState<ChildWithPosition>({ children, camPosition, renderPosition });
  const [nextChild, setNextChild] = useState<ChildWithPosition | undefined>(undefined);

  useEffect(() => {
    if (camPosition === currChild.camPosition && renderPosition === currChild.renderPosition) {
      setCurrChild((x) => ({ ...x, children }));
    } else if (renderPosition !== currChild.renderPosition) {
      setNextChild({ children, camPosition: camPosition, renderPosition });
    } else if (camPosition !== currChild.camPosition) {
      setCurrChild({ children, renderPosition, camPosition: camPosition });
    }
  }, [children, currChild.camPosition, camPosition, renderPosition]);

  return (
    <div
      className={'background_root'}
      style={{
        backgroundImage: `url(${background})`,
        transform: `translateX(-${HARD_CODED_SCREEN_WIDTH * camPosition}px)`,
        transitionDuration: BACKGROUND_TRANSITION_MS + 'ms',
      }}
      onTransitionEnd={(e) => {
        // Something on a child finished it's transition
        if (e.target !== e.currentTarget) {
          return;
        }

        setCurrChild(nextChild ?? currChild);
        setNextChild(undefined);
        if (onReached) {
          onReached();
        }
      }}
    >
      <div
        className={'background_child-container'}
        style={{ left: currChild.renderPosition * HARD_CODED_SCREEN_WIDTH }}
        key={currChild.renderPosition}
      >
        {currChild.children}
      </div>
      {nextChild !== undefined && (
        <div
          className={'background_child-container'}
          style={{ left: nextChild.renderPosition * HARD_CODED_SCREEN_WIDTH }}
          key={nextChild.renderPosition}
        >
          {nextChild.children}
        </div>
      )}
    </div>
  );
};
