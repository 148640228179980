import React, { useEffect, useState } from 'react';
import InputMasked from '../InputMasked/InputMasked';

const SentencePuzzle = ({ sentence = '', word = '', isVisibleWord = false, className = '' }) => {
  const [sentenceParts, setSentenceParts] = useState<Array<string>>([]);

  useEffect(() => {
    const pieces = (sentence || '').split(' ');
    setSentenceParts(pieces);
  }, [sentence]);

  return (
    <div className={className}>
      {sentenceParts.map((part, idx) => (
        <span key={`key-${idx}`} className={'mx-2 d-inline-block'}>
          {part.includes('_') ? <InputMasked word={word} isVisible={isVisibleWord} /> : part}
        </span>
      ))}
    </div>
  );
};

export default SentencePuzzle;
