import React, { useCallback } from 'react';

const Hint = ({ word = '', hintLevel }: { word: string; hintLevel: number }) => {
  const wordChars = word.split('');
  const wordCharsLength = wordChars.length;
  console.log(hintLevel);
  const showLetterCondition = useCallback(
    (idx: number, currentWordPart: string) => {
      if (hintLevel > 0) {
        if (idx === 0) {
          return true;
        }
        if (hintLevel > 1 && currentWordPart.length > 3) {
          if (idx === wordCharsLength - 1) {
            return true;
          }
          if (hintLevel > 2) {
            if (idx % 5 === 0) {
              return true;
            }

            if (hintLevel > 3) {
              if (idx % 3 === 0) return true;
            }
          }
        }
      }

      return false;
    },
    [hintLevel, wordCharsLength, word]
  );
  return (
    <>
      {' '}
      {word.split(' ').map((singleWorld, index) => (
        <div className={'flex'} key={index}>
          {singleWorld.split('').map((c, idx) => (
            <div
              key={`c-${idx}`}
              className={
                (singleWorld.length >= 12 ? 'mx-0' : singleWorld.length >= 9 ? 'px-0 mx-1' : 'px-1 mx-1') +
                ' font-family-monospaced text-center d-inline-block text-uppercase'
              }
              style={{ borderBottom: !c.trim() ? 'none' : '3px solid' }}
            >
              {showLetterCondition(idx, singleWorld) && singleWorld.length >= 3 ? (
                <span>{c.trim() ? c : '\u00A0'}</span>
              ) : (
                <span style={{ opacity: 0 }}>a</span>
              )}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
export default Hint;
