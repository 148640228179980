const PLAYING_CARDS: { content: string; words: string[] }[] = [
  {
    content: 'My phone only has pictures of _ on it.',
    words: ['Duck Shaped Rocks', 'Diarrhea', 'Murder', 'Tourists', 'Florida'],
  },
  {
    content: 'I always feel guilty when I step on _.',
    words: ['Smurfs', 'Grass', 'Earth', 'Your Diorama', 'Dog Toys'],
  },
  { content: '_ is the new 40.', words: ['Dabbing', 'Olde English', 'Slurping', 'Harry Potter', 'Lip Hair'] },
  {
    content: '_ can also be used as batteries.',
    words: ['Zombies', 'Jeep Cherokees', 'Accountants', 'Polar Bears', 'Darth Vader'],
  },
  {
    content: 'For some quick cash, try selling _.',
    words: ['CIA Secrets', 'Elon Musk', 'Nacho Cheese', 'Loose Gravel', 'Handprints'],
  },
  {
    content: 'Global warming is actually caused by _.',
    words: ['David Hasselhoff', 'MAGA Hats', 'Texting', 'Oprah', 'Goofy'],
  },
  {
    content: "I hate to break it to you, but you've got _ for eyes.",
    words: ['Mushrooms', 'Sloths', 'Dice', 'Knives', 'Dollar Signs'],
  },
  {
    content: 'In prison spooning actually means _.',
    words: ['Shaving', 'Catapulting', 'Marriage', 'Square Dancing', 'Pilates'],
  },
  {
    content: 'Instead of declaring bankruptcy, I declare _.',
    words: ['Sweaty Armpits', 'War', 'The Fifth', 'Oreo Shakes', 'Pear Trees'],
  },
  {
    content: "It's not you, it's your _.",
    words: ['Ferocious Badger', 'WiFi', 'Rottweiler', 'Cornbread', 'Booger Collection'],
  },
  {
    content: 'These zombies are defective, they keep eating _.',
    words: ['Starbucks', 'Toilet Paper', 'Crops', 'Lucky Charms', 'Carp'],
  },
  {
    content: 'Feeling sad? Just rub _ all over your body!',
    words: ['Rotten Bananas', 'A ladle of salt', 'Glitter glue', 'Mango', 'Olive oil'],
  },
  {
    content: "I didn't expect to find _ in my bed this morning.",
    words: ['A Scuba Diver', 'Oodles of noodles', 'Enlightenment', 'Crop circles', 'Humble pie'],
  },
  {
    content: 'I thought my dreams were out of reach until I discovered _.',
    words: ['Pickleball', 'Fingerless gloves', 'Coffins', 'Detectives', 'Independence'],
  },
  { content: '_ will cause World War 3.', words: ['TikTok', 'TV', 'Childbirth', 'Toddlers', 'Dating apps'] },
  {
    content: 'It sounded weird to me too, but trust me, try _ with ketchup!',
    words: ['Porridge', 'Baseball', 'Jello', 'Fruit salad', 'Hot chocolate'],
  },
  {
    content: 'My basement is full of _.',
    words: ['Rotten Milk', 'Candy Wrappers', 'Muskrats', 'Hungry Ghosts', 'Peanut Butter Cups'],
  },
  {
    content: 'Finally, a Netflix show about _.',
    words: ['Sagging', 'Curling', 'Onion Rings', 'Weiner Dogs', 'Buttcracks'],
  },
  {
    content: "Tonight I'm going to light a candle and slip into a bath with _.",
    words: ['Lady Liberty', 'Tomato Sauce', 'Ten Clowns', 'Crumby Bread', 'House Plants'],
  },
  {
    content: "I'm campaigning to make national _ day a reality.",
    words: ['Little spoon', 'Pillow Fort', 'Box of wine', 'Screwdriver', 'Acorn'],
  },
  {
    content: "There isn't a single statue that wouldn't look better with _.",
    words: ['Fairy lights', 'A Christmas Sweater', 'Cat ears', 'Angel wings', 'Fireworks'],
  },
  {
    content: "Sorry I'm late, I got distracted by _ again!",
    words: ['Tater tots', 'Eels', 'Blinking Lights', 'UFOs', 'My Reflection'],
  },
  {
    content: 'For my next trick, I will make _ disappear!',
    words: ['Vowels', 'Electricity', 'A Slushie', 'Bacon', 'Social media'],
  },
  {
    content: 'The key to a successful job interview is _ them in the eye.',
    words: ['Poking', 'Stapling', 'Shooting', 'Licking', 'Squeezing'],
  },
  {
    content: 'Instead of health insurance I just use _.',
    words: ['Mayonnaise', 'My Fists', 'A Horseshoe', 'Glue Guns', 'Astrology'],
  },
  {
    content: 'Is it just me, or does this salad taste like _.',
    words: ['Bandaids', 'Raw Sewage', 'Beetlejuice', 'Leftover Fish', 'Cat Food'],
  },
  {
    content: 'I sleep with one eye open and my hands on my _.',
    words: ['Tearstained cheeks', 'Knitting needles', 'Steering Wheel', 'Abs', 'Encyclopedia'],
  },
  {
    content: "Humans can't survive without _.",
    words: ['CSI Miami', 'Ice Cream', 'Grocery Carts', 'Oil', 'Heads'],
  },
  {
    content: 'Talk to your doctor about _ today.',
    words: ['Pine Needles', 'Tennis', 'Groundhogs', 'Broken Bones', 'Homemade Candles'],
  },
  {
    content: 'I really regret my tattoo of _.',
    words: ['My Child', 'Irises', 'Facebook', 'Leg Hair', 'Ben Franklin'],
  },
  {
    content: 'The most challenging yoga position is the inward _.',
    words: ['Twisty Tie', 'Jungle Gym', 'Crocodile', 'Eyebrow', 'Hug'],
  },
  {
    content: 'Forget pain meds, give me _.',
    words: ['Baby Animals', 'Hammer Time', 'Diapers', 'Death', 'The Moon'],
  },
  {
    content: 'Did somebody order a forklift load of _?',
    words: ['Coronavirus', 'Soup Spoons', 'Gophers', 'Pineapple Pizza', 'Enter Keys'],
  },
  {
    content: 'You can pick a good watermelon by _ it.',
    words: ['Eating', 'Loving', 'Smooshing', 'Spinning', 'Asking'],
  },
  {
    content: "NASA's latest space shuttle is powered solely by _.",
    words: ['Men in tights', 'Food waste', 'Googly eyes', 'Squirrels', 'Tweets'],
  },
  {
    content: 'Most decent country songs complain about _.',
    words: ['Tumbleweeds', 'Women', 'Horse Flies', 'Greased Slides', 'Nickels'],
  },
  {
    content: 'Soon, video games will have _.',
    words: ['House Chores', 'Big Bird', 'Tentacles', 'Girl Mario', 'Saddles'],
  },
  {
    content: 'Forget milking a cow, try _ it.',
    words: ['Shoving', 'Piercing', 'Educating', 'Dunking', 'Splashing'],
  },
  {
    content: "I've always longed to experience _.",
    words: ['Love', 'Advanced arithmetic', 'BBQ seasoning', 'Friendship', 'Scoring'],
  },
  {
    content: 'Nothing excites me like _.',
    words: ['Bad breath', 'Furry fruit', 'Fishing hooks', 'Tuna', 'Clothes pegs'],
  },
  {
    content: 'The only thing worse than a mouthful of bees is a mouthful of _.',
    words: ['Rusty Nails', 'Meatloaf', 'Raisins', 'Seaweed', 'Angry Gerbils'],
  },
  { content: "You can't teach _.", words: ['Thickness', 'Grapes', 'Tadpoles', 'Wavy Hair', 'Moose Wrangling'] },
  {
    content: 'Our natural yogurt is made with real _.',
    words: ['Human flesh', 'Sawdust', 'Pork', 'Pesto', 'Cider'],
  },
  {
    content: 'Humans are 60 percent water and 40 percent _.',
    words: ['Ketchup', 'Beefsteak', 'Boomerang', 'Bugs', 'Arm Fat'],
  },
  {
    content: "I'd love to look like _.",
    words: ['Margaret Thatcher', 'Count Dracula', 'David Bowie', 'Beyonce', 'Mark Zuckerberg'],
  },
  {
    content: 'As he was making the earth, God made only one mistake _.',
    words: ['Clowns', 'Parking wardens', 'Men', 'Belly buttons', 'Mosquitos'],
  },
  {
    content: '_ has replaced milk on my cereal.',
    words: ['Clam chowder', 'Pickle juice', 'Hot sauce', 'Butter', 'Salmon'],
  },
  {
    content: 'Moms are always putting _ in vases.',
    words: ['Slam Dunks', 'Stick Bundles', 'Eggs', 'Baby Teeth', 'Jackets'],
  },
  {
    content: 'It started as an accident, but now wearing only _ is the latest fashion trend.',
    words: ['Aprons', 'Glass clogs', 'Clown shoes', 'Sunglasses', 'Suede'],
  },
  {
    content: 'My _ is the perfect substitute for toilet paper.',
    words: ['Oldest tshirt', 'Best friend', 'Underarm hair', 'Dog', 'Hairbrush'],
  },
  {
    content: "When they make a statue of me, I'll be holding my _.",
    words: ['Smooth buttocks', 'Knobbly knees', 'Comic books', 'Toes', 'Houseplants'],
  },
  {
    content: 'Elon Musk is trying to purchase _.',
    words: ['Tallahassee', 'The letter P', 'Respect', 'The Internet', 'Capitalism'],
  },
  {
    content: 'Nothing can get between me and my precious _.',
    words: ['Sticker collection', 'Lifesize doll', 'Doormat', 'Blanket', 'Water bottle'],
  },
  {
    content: "It's been a strange end of the world everyone wiped out by _.",
    words: ['Sentient refrigerators', 'Tiny pigs', 'Frozen peas', 'Junk mail', 'Butterflies'],
  },
  {
    content: 'Forgot your gloves? Just use _ to keep your hands warm!',
    words: ['Dung beetles', 'Scorn', 'Microwaves', 'Salad', 'Shoes'],
  },
  {
    content: 'We recently discovered evidence of an ancient civilization who worshipped _.',
    words: ['Screaming goats', 'Icicles', 'Bowel movements', 'Burritos', 'Clouds'],
  },
  {
    content: '_ is my dream holiday.',
    words: ['Mars', 'A desert island', 'A tent in Wales', 'Bermuda', 'Antarctica'],
  },
  {
    content: 'I use _ to hold up my pants.',
    words: ['Rabid Monkeys', 'Paper Straws', 'Earthworms', 'Muscle', 'Snake Skin'],
  },
  {
    content: 'The number one reason for poverty is _.',
    words: ['Donuts', 'Christmas', 'Monopoly Guy', 'Amazon Prime', 'Bills'],
  },
  {
    content: '_ may look like fun, but trust me, it is not!',
    words: ['Aura cleansing', 'Exorcism', 'A career', 'Adulthood', 'Rap'],
  },
  {
    content: "Keep _ in your kitchen and you'll never go hungry.",
    words: ['Shrivelled limes', 'Dinosaur figurines', 'Instant soups', 'Disinfectant', 'God'],
  },
  {
    content: 'A good roommate always helps out with the _.',
    words: ['Pimple Popping', 'Wind Socks', 'Potato Peeling', 'Patty Cake', 'Serenading'],
  },
  {
    content: 'I keep my _ taped to my leg.',
    words: ['Gogurt', 'Protractor', 'Scuba Gear', 'Spare Tire', 'Lunch Meat'],
  },
  {
    content: 'Rap videos are nothing without _.',
    words: ['Top Hats', 'Bazookas', 'Veggies', 'Charcuterie Boards', 'Jazz Hands'],
  },
  {
    content: 'My dentist told me I had to stop eating _.',
    words: ['Toothpaste', 'Alcoholic chocolates', 'Garlic cloves', 'Refried beans', 'Tapeworms'],
  },
  {
    content: '_ is the only thing that will survive the apocalypse.',
    words: ['Fake News', 'Pegged Jeans', 'Beatles', 'Ghosting', 'Marvel Films'],
  },
  {
    content: "Bigfoot's feet smell like _.",
    words: ['Heaven', 'Bambi', 'Cheeseburgers', 'Shrek', 'Lisa Simpson'],
  },
  {
    content: 'Instead of deodorant, try _ .',
    words: ['Poop', 'Bathing', 'Tuna Fish', 'Social Distancing', 'Amputation'],
  },
  {
    content: '_ is the best medicine.',
    words: ['A Hammer', 'Chocolate', 'Pill Bottle', 'Drowning', 'Smooching'],
  },
  { content: '_ On Ice!', words: ['Rollerblading', 'Jenga', 'Teachers', 'Charlie Chaplin', 'Stranger Things'] },
  {
    content: 'A string quartet is incomplete without _.',
    words: ['Slapping', 'Vampire Bats', 'Backstabbing', 'Snoring', 'Pony Tails'],
  },
  { content: 'All I want is some _.', words: ['Sugar', 'Mashed Potatoes', 'Polka', 'Dookie', 'Jello Salad'] },
  {
    content: 'All your donations actually go to _.',
    words: ['Pockets', 'Peloton', 'Switzerland', 'The Wealthy', 'Landfills'],
  },
  {
    content: "Babysitting isn't so hard as long as you bring enough _.",
    words: ['Tranquilizer', 'Jawbreakers', 'Video Games', 'Electronic Devices', 'Duct Tape'],
  },
  {
    content: 'Baseball would be better if it included _.',
    words: ['Scooter Boys', 'Rabid Geese', 'Lava', 'Go Karts', 'Fly Paper'],
  },
  {
    content: 'Billionaires are blasting _ into space.',
    words: ['Whales', 'Children', 'Hedge Funds', 'Tooth Fairies', 'Chicago Cubs'],
  },
  {
    content: 'Breaking News! _ are on the loose!',
    words: ['Grandmas', 'Apples', 'Scissors', 'Squirrells', 'Moose'],
  },
  {
    content: 'Christopher Columbus also discovered _.',
    words: ['Science', 'Warts', 'Penguins', 'Beanie Babies', 'The Beatles'],
  },
  { content: "Don't blame me, I voted for _.", words: ['Bubbles', 'Uranus', 'Minions', 'Caillou', 'Deep Dish'] },
  {
    content: "Don't look now, but there's a _ on your back.",
    words: ['Prince', 'Mouse Turd', 'Chicken Wing', 'Pound of Beef', 'Platypus'],
  },
  { content: 'Every morning I reach for a hot cup of _.', words: ['Beetles', 'Danger', 'Sleep', 'Ice', 'Soup'] },
  {
    content: "Genghis Khan's Twitter handle would have been _.",
    words: ['Sasquatch', 'Ding Dong', 'Asia', 'Back Door', 'Your Dad'],
  },
  { content: 'I have a glowing _.', words: ['Mechanic', 'Elbow', 'Armpit', 'Tummy', 'Goose'] },
  {
    content: "If aliens visited Earth, they'd probably hate _.",
    words: ['Traffic', 'Mickey Mouse', 'The President', 'Lemonade Stands', 'Peeps'],
  },
  {
    content: 'If I were in charge every crawl space would have _.',
    words: ['Porcelain Dolls', 'Mouse Traps', 'Guards', 'Slip N Slides', 'Shaq'],
  },
  {
    content: "If it were up to me, we'd all be worshiping _.",
    words: ['Cellphones', 'Hip Hop', 'Drunk Spiders', 'Pillow Forts', 'Cheetos'],
  },
  {
    content: "If nothing else, you'll always have _.",
    words: ['Arthritis', 'Chicken Thighs', 'High Fives', 'Tattoos', 'Toenail Fungus'],
  },
  {
    content: 'If you want to sit by yourself on the train, you just need a _.',
    words: ['Wide Stance', 'Sword', 'Microwaved Fish', 'Shirtless Viking', 'Big Fart'],
  },
  {
    content: "I'm not gonna bet on a horse named _.",
    words: ['Glue', 'Tripod', 'Pack Mule', 'Klutzy', 'Ground Beef'],
  },
  {
    content: "I'm not psychotic, I just enjoy eating _.",
    words: ['Humans', 'Pickled Feet', 'Doors', 'Kittens', 'Tin Cans'],
  },
  {
    content: "I'm really worried about the ghost of _ coming back.",
    words: ['Scooby Doo', 'Mexican Food', 'America', 'King Kong', 'Ruth Bader Ginsburg'],
  },
  {
    content: 'It takes a lot of _ to be a champion.',
    words: ['Crypto', 'Hot Dogs', 'Googling', 'Handshakes', 'Rubberbands'],
  },
  {
    content: 'Joe Biden is actually just _ in a suit and glasses.',
    words: ['A Skeleton', 'Clark Kent', 'A Leprechaun', 'Shaved Mice', 'Queen Elizabeth'],
  },
  {
    content: 'Kids these days will never understand _.',
    words: ['Alf', 'Waiting', 'Ozzy Osbourne', 'Telephones', 'Cricket'],
  },
  {
    content: 'Life is much better since I quit _.',
    words: ['Red Bull', 'Bleeding', 'Vomiting', 'Using Toilets', 'Juggling Chainsaws'],
  },
  {
    content: 'My favorite wrestling move is the _.',
    words: ['Grumpy Pirate', 'Salted Pretzel', 'Flounder', 'Belly Slap', 'Wedgie'],
  },
  {
    content: 'My rapper name is Lil _.',
    words: ['Scribbles', 'Captain Hook', 'Wrapper', 'Pinky Nail', 'Miss Marple'],
  },
  {
    content: 'My weakness is _.',
    words: ['Vampire Bites', 'Muscles', 'Avocado', 'Gunshot Wounds', 'Light Up Shoes'],
  },
  {
    content: 'Pro Tip Fill your water bottle full of _.',
    words: ['Deer Tears', 'BLTs', 'Chocolate Mousse', 'Dreams', 'Football Injuries'],
  },
  {
    content: 'Radioactive _ make for the worst super power.',
    words: ['Fingernails', 'Slushies', 'Shellfish', 'Cantaloupes', 'Beans'],
  },
  { content: 'Robots stole my _.', words: ['Boat', 'Kneecaps', 'Dad', 'Face', 'Sailboat'] },
  {
    content: 'So what if I named my boat _?',
    words: ['SS Titanic', 'Jaws', 'The Confederacy', 'ISIS', 'Lunch Pail'],
  },
  {
    content: 'Soccer was originally played with a _.',
    words: ['Monkey Head', 'Stuffed Turkey', 'Grocery Bag', 'Bedpost', 'Pigeon'],
  },
  {
    content: "The 90's called, they want their _ back.",
    words: ['Lunch', 'Jar Jar', 'Cassettes', 'Racism', 'Walkman'],
  },
  {
    content: 'The last thing I bought at a store was _.',
    words: ['Micro Plastic', 'Guns', 'Licorice Rope', 'Sardines', 'Socks'],
  },
  { content: 'The new mansplaining is man _.', words: ['Oozing', 'Juicing', 'Peeling', 'Holes', 'Jumping'] },
  {
    content: "The one thing you can't get on Amazon is _.",
    words: ['Boogeyman', 'Drunk', 'Your Ex', 'Denver Broncos', 'Bond James Bond'],
  },
  {
    content: 'The real magic trick is pulling _ out of your hat.',
    words: ['DB Cooper', 'Gandalf', 'Snow People', 'Child Support', 'Loose Lugnuts'],
  },
  {
    content: 'The scariest thing to see in a dark alley would be _.',
    words: ['Mirrors', 'Armed Emus', 'Police', 'Winnie The Pooh', 'Me'],
  },
  {
    content: 'The trouble with kids is all the _.',
    words: ['Greasy Hands', 'Feeding', 'TikTok Dances', 'Head Bugs', 'Frogs'],
  },
  { content: 'The worst game show prize is _.', words: ['Lions', 'A Paddling', 'DVDs', 'Jail', 'Purple Nurple'] },
  {
    content: 'The worst mascot is a _.',
    words: ['Roly Poly', 'Mouse Fart', 'Club Sandwich', 'Stiff Breeze', 'Donald J Trump'],
  },
  { content: 'The worst thing to put on pizza is _.', words: ['Yogurt', 'Forks', 'Wool', 'Fruit', 'A Hat'] },
  {
    content: "There's the luck of the Irish, and then there's the _ of the Scottish.",
    words: ['Flat Feet', 'Kilt', 'Beard', 'Squid', 'Stare'],
  },
  {
    content: 'This house is haunted by _.',
    words: ['Ninjas', 'Leprechauns', 'Knickknacks', 'Farts', 'Young Adults'],
  },
  {
    content: 'Well, we got rid of the ants, but now we have _.',
    words: ['FIRE', 'Aardvarks', 'Lizards', 'Pink Eye', 'Slick Floors'],
  },
  {
    content: "Whatever you do, don't look up _ on the internet!",
    words: ['Cheeks', 'Bacne', 'Mom', 'Open Sore', 'Illness'],
  },
  {
    content: 'When I see an oversized load I think of _.',
    words: ['American Food', 'Grimace', 'Pork Chops', 'Donkey Kong', 'Forklifting'],
  },
  {
    content: 'Who ordered 1,000 _?',
    words: ['Foot Fives', 'Mouseketeers', 'Fake Pukes', 'Burnt Chimichangas', 'Door Stops'],
  },
  {
    content: 'Why do Grandmas always have _ in their homes?',
    words: ['K Pop', 'Magazines', 'Dead Flowers', 'Nutcrackers', 'Oils'],
  },
  {
    content: 'Why is that dog chewing on _?',
    words: ['Batman', 'The Neighbors', 'Soap', 'The Walls', 'Bubblegum'],
  },
  { content: "You can't eat _ for every meal.", words: ['Popcorn Balls', 'Cod', 'Boogers', 'Cheese', 'Brains'] },
  {
    content: "There's only one thing that always calms me down _.",
    words: ['Chaos', 'Quiet prayer', 'Heavy metal', 'Banjo', 'Lightning'],
  },
  {
    content: '_ keeps me awake at night.',
    words: ['The justice system', 'Lacking empathy', 'Deforestation', 'Religion', 'Grammar'],
  },
  {
    content: '_ gets me in the mood for dancing.',
    words: ['Flatulence', 'Tax evasion', 'Combustion', 'Fieldwork', 'Fire safety'],
  },
  {
    content: 'It may have been a bad day, but I sure did learn a lot about _.',
    words: ['Field medicine', 'Origami', 'Being a werewolf', 'Safety', 'Country music'],
  },
  {
    content: '_ makes every school trip better.',
    words: ['Global warming', 'Dirty laundry', 'Hay Fever', 'Rhyming', 'Archery'],
  },
  {
    content: '_ is not a good excuse to skip school.',
    words: ['Body odor', 'Wordless terror', 'Genome sequencing', 'DNA testing', 'Revenge'],
  },
  {
    content: '_ is the worst thing you can get from a claw crane arcade game.',
    words: ['Tetanus', 'Heatstroke', 'Food poisoning', 'A scolding', 'Honor'],
  },
  {
    content: "My doctor's diagnosed the source of my symptoms _.",
    words: ['Acne', 'Heartache', 'Disco fever', 'Hunger', 'Pride'],
  },
  {
    content: 'Government officials reassured the public that 5G does not cause _.',
    words: ['Emotional vulnerability', 'Mutation', 'Xray vision', 'Memory loss', 'Happiness'],
  },
  {
    content: '_ was the inspiration for my latest painting.',
    words: ['Naturism', 'Dread', 'The holy ghost', 'Excellence', 'Sushi'],
  },
  { content: 'My best outfit has a real _ vibe.', words: ['Party', 'Groovy', 'Country', 'Spooky', 'Edgy'] },
  {
    content: 'Now it all makes sense my great great grandad was _!',
    words: ['Really old', 'Scandalous', 'Naughty', 'Enormous', 'Blue'],
  },
  { content: 'Maybe getting a pet _ was a mistake.', words: ['Rhino', 'Piranha', 'Cobra', 'Dragon', 'Hippo'] },
  {
    content: 'Honestly Miss, my _ ate my homework!',
    words: ['Alligator', 'Cockatoo', 'Beaver', 'Anaconda', 'Millipede'],
  },
  {
    content: 'The _ is actually the most dangerous animal at the zoo.',
    words: ['Flamingo', 'Penguin', 'Flying squirrel', 'Turtle', 'Zookeeper'],
  },
  {
    content: 'Every month, when the moon is full, I turn into a _.',
    words: ['Suckling pig', 'Partridge', 'Redneck', 'Trex', 'Giraffe'],
  },
  {
    content: '_ is my favorite TV character.',
    words: ['Buffy', 'Tyrion Lannister', 'Barney', 'Homer Simpson', 'SpongeBob'],
  },
  {
    content: "I couldn't stand being locked in a room with _.",
    words: ['My parents', 'Owen Wilson', 'Baby Jesus', 'Your mother', 'Donald Trump'],
  },
  {
    content: "When I'm reincarnated, I want to come back as _.",
    words: ['The devil', 'A stick insect', 'Fred Weasley', 'A princess', 'Your father'],
  },
  {
    content: 'I could happily be left on a desert island with only _ for company.',
    words: ['Prince William', 'Susan Boyle', 'Santa Claus', 'Hermione', 'Myself'],
  },
  { content: "I'd die for _.", words: ['Emma Watson', 'Daenerys', 'Cookie monster', 'Miley Cyrus', 'Bob Ross'] },
  {
    content: "It's more annoying than scary I'm haunted by the ghost of _.",
    words: ['Queen Victoria', 'Postman Pat', 'Benjamin Franklin', 'Tupac'],
  },
  {
    content: "Wear _ to an interview and you're sure to get the job!",
    words: ['Double denim', 'Multiple hats', 'Banana bandanas', 'Ribbons', 'Jewelry'],
  },
  {
    content: "I don't feel good maybe I shouldn't have eaten that _.",
    words: ['Superglue', 'Leftover lasagna', 'Sardine sandwich', 'Mystery meat', 'Quiche'],
  },
  {
    content: '_ is the secret ingredient in my famous stew.',
    words: ['Nut butter', 'Cotton candy', 'Crispy fat', 'Apple sauce', 'Smoke'],
  },
  {
    content: "I've turned into a monsterwith a craving for _!",
    words: ['Hot coleslaw', 'Turducken', 'Watermelon', 'Frog spawn', 'Hummus'],
  },
  {
    content: 'My parents taught me the importance of _.',
    words: ['Flirting', 'Knocking first', 'Retelling jokes', 'Fishing', 'Weightlifting'],
  },
  {
    content: 'Competitive _ is my new favorite sport.',
    words: ['Yawning', 'Flossing', 'Pasta straining', 'Drinking', 'Ironing'],
  },
  {
    content: "_ is the reason I'm banned from Canada.",
    words: ['Giggling', 'Wedding crashing', 'Biting', 'Hunting', 'Directing traffic'],
  },
  {
    content: '_ only makes things worse.',
    words: ['Grinning toothlessly', 'Skipping', 'Using both hands', 'Parking', 'Fly fishing'],
  },
  {
    content: '_ will set you free.',
    words: ['Telling jokes', 'Shoplifting', 'Honoring Satan', 'Scaring kids', 'Hopping'],
  },
  {
    content: '_ works great as an alternative to getting vaccinated.',
    words: ['Praying hard', 'Using crystals', 'Gobbling candy', 'Digging holes', 'Snoozing'],
  },
  {
    content: '_ is a surprisingly effective way to put out a fire.',
    words: ['Singing ABBA', 'Stretching', 'Moisturizing', 'Abseiling', 'Live Streaming'],
  },
  {
    content: '_ is the best remedy for a cold.',
    words: ['Tickling', 'Gargling mayonnaise', 'Poppin bottles', 'Wailing', 'Shuffling'],
  },
  {
    content: 'He died doing what he loved _.',
    words: ['Churning butter', 'Scribbling on desks', 'Skinny dipping', 'Tanning', 'Shooting hoops'],
  },
  {
    content: '_ regularly is the secret to eternal youth.',
    words: ['Practicing witchcraft', 'Getting low', 'Spying', 'Recycling', 'Timetraveling'],
  },
  {
    content: "_ is the most fun you can have when you're home alone.",
    words: ['Summoning demons', 'Calling mom', 'Getting hangry', 'Double dipping', 'Cleaning'],
  },
  {
    content: '_ is the worst way to spend your birthday.',
    words: ['Caring', 'Cleaning the moat', 'Feeding dragons', 'Getting older', 'Washing'],
  },
  {
    content: "Astrologists have proclaimed the year of the _. I can't wait!",
    words: ['Naked molerat', 'Big toe', 'Creepy doll', 'Demon', 'Snapback'],
  },
  {
    content: 'When the time comes, I will bow down to our new _ overlords.',
    words: ['Robot', 'Spaghetti', 'Zombie', 'Toaster', 'Lizard'],
  },
  {
    content: "If I was a superhero, I'd be Captain _.",
    words: ['Pineapple', 'Duck Hands', 'Toilet seat', 'Ice cube', 'Hot dog'],
  },
  {
    content: 'Trouble sleeping? Just put a _ under your pillow.',
    words: ['Cold sausage', 'Mousetrap', 'Tiny devil', 'Hula hoop', 'Tuba'],
  },
  {
    content: 'My recurring nightmare? A witch turns me into a _!',
    words: ['Terrible singer', 'Tiny Giraffe', 'Paperclip', 'Water fountain', 'Builder'],
  },
  {
    content: "Sorry for the delay, we've been told there's a _ on the runway.",
    words: ['Scary spider', 'Enormous jigsaw', 'Sleeping koala', 'Ham', 'Trampoline'],
  },
  {
    content: '_ farming is fast becoming a respectable career.',
    words: ['Button', 'Harmonica', 'Snowflake', 'Nerd', 'Skeleton'],
  },
  {
    content: '_ hunters is my new favorite TV show.',
    words: ['Nut', 'Bookcase', 'Nokia', 'Chipmunk', 'Slipper'],
  },
  {
    content: 'Burglars broke into my house, but all they took was my _.',
    words: ['Nose', 'Scrapbook', 'Bucket hat', 'Lunchbox', 'Beanbag'],
  },
  {
    content: 'My _ is front and center on the new family crest.',
    words: ['Playstation', 'Hairy knuckle', 'Retainer', 'Cousin', 'Chin'],
  },
  {
    content: 'I want to die peacefully with my _ by my side.',
    words: ['Stamp collection', 'Yoga mat', 'Favorite food', 'First teacher', 'Breadmaker'],
  },
  {
    content: "A _ is the last thing you'd want to meet in a dark alley.",
    words: ['Standup comic', 'Underripe banana', 'Talking mouse', 'Beehive', 'Yeti'],
  },
  {
    content: 'Mad science crossing a normal, healthy human with a _!',
    words: ['Debt collector', 'Bearded dragon', 'Alarm clock', 'Flashlight', 'Fox'],
  },
  {
    content: 'A _ a day keeps the doctor away.',
    words: ['Top hat', 'Scratchcard', 'Doorknob', 'Placard', 'Sandwich'],
  },
  {
    content: "I'm not alone in this cave there's a _ in here with me!",
    words: ['Honey badger', 'Pro wrestler', 'Wild boar', 'Troll', 'Idiot'],
  },
  {
    content: 'If I could only have one thing for the rest of my life, it would be _.',
    words: ['Skittles', 'Sheep', 'Pretzels', 'Coconuts', 'Umbrellas'],
  },
  {
    content: "There's nothing I enjoy more than a big jar of _.",
    words: ['Ants', 'Exotic spices', 'Eyeballs', 'Jalapenos', 'Highlighters'],
  },
  {
    content: 'The surgery went wrong, and I woke up with _ for hands!',
    words: ['Hooves', 'Digital radios', 'Ears', 'Pitchforks', 'Paddles'],
  },
  {
    content: 'Look who just came inside with _ in its mouth. Bad dog!',
    words: ['Snow Globes', 'Delicious crayons', 'Dumbbells', 'Newspapers', 'Hangers'],
  },
  {
    content: "I think something's wrong with the Tooth Fairy they left _ under my pillow!",
    words: ['Animal skulls', 'Old photos', 'Takeaway menus', 'Nachos', 'Eyelashes'],
  },
  {
    content: '_ have just been discovered on Mars!',
    words: ['Twinkies', 'Violins', 'Collapsible chairs', 'Texans', 'iPod'],
  },
  {
    content: "I've always loved finding _ in my pockets.",
    words: ['Baguettes', 'Guinea pigs', 'Loose Change', 'Bowties', 'Rings'],
  },
  {
    content: "I don't have any cash on me, but maybe I can pay you with _ instead?",
    words: ['Hotel toiletries', 'Nail clippings', 'Advent calendars', 'Jigsaw pieces', 'Ice cubes'],
  },
  {
    content: "I'm not sure it was the Easter Bunny who left _ all over our garden",
    words: ['Burger buns', 'Sunscreen bottles', 'Angry essays', 'Beers', 'Footballs'],
  },
  {
    content: 'My first action if elected? Fill the entire country with _!',
    words: ['Smooth jazz', 'Red pandas', 'Beach balls', 'Hedgehogs', 'Heathens'],
  },
  {
    content: 'When I go to sleep happy, I dream about _.',
    words: ['Fat toads', 'Space exploration', 'Rusty kettles', 'Tractors', 'Dollar bills'],
  },
  {
    content: 'Is something missing from your life? Try _ today!',
    words: ['Crying', 'Vegan cheese', 'Laxative pills', 'Growth hormones', 'Gymnastics'],
  },
  {
    content: 'My theme park idea? World of _.',
    words: ['Snot', 'Cottage cheese', 'Broken dreams', 'Crab legs', 'Staplers'],
  },
  {
    content: "Laugh all you want, but I'm deathly afraid of _.",
    words: ['Newborn babies', 'Teddy bears', 'Waffle cones', 'Affection', 'Sneezes'],
  },
  {
    content: "If _ made you rich, I'd be a millionaire!",
    words: ['Dirty fingernails', 'Food cans', 'Disillusionment', 'Coffee grounds', 'Stuffed animals'],
  },
  {
    content: 'Start your day right with _ every morning.',
    words: ['Raw broccoli', 'Large buns', 'Fire Breathing', 'Explicit lyrics', 'Bells'],
  },
  {
    content: 'Need a present for someone who has everything? Have you considered _?',
    words: ['Table manners', 'After dinner mints', 'Lampshades', 'Vaccination', 'Olives'],
  },
  {
    content: 'I want to open a shop that sells nothing but _.',
    words: ['Fake Mustaches', 'Fried eggs', 'Freedom', 'Time', 'Annoying siblings'],
  },
  {
    content: 'If loving _ is a crime, then lock me up!',
    words: ['Frenemies', 'Vampire novels', 'Savoury milkshakes', 'Ketchup packets', 'Seeds'],
  },
  {
    content: "I've decided to sell everything and invest all my money in _.",
    words: ['Dungarees', 'Mouse pads', 'Hydroponics', 'Cryptocurrency', 'Water wings'],
  },
  {
    content: '_ all day, every day.',
    words: ['Thumb tacks', 'Belly piercings', 'Corporate acronyms', 'Madness', 'Nutrition'],
  },
  {
    content: "Put _ in a museum and I'd visit every week.",
    words: ['Loneliness', 'Soggy socks', 'Cold pizza', 'Cereal', 'Hydration'],
  },
  {
    content: "Thanks Mom, I've always wanted _ for Christmas!",
    words: ['Male role models', 'Laundry detergent', 'Pool noodles', 'Enormous ankles', 'Wigs'],
  },
  {
    content: 'Brace yourselves for unusual weather this week beware of _.',
    words: ['Flimsy frisbees', 'Spitting camels', 'Ladybugs', 'Pinecones', 'Sharks'],
  },
  {
    content: "I couldn't do my job without _.",
    words: ['Earlobes', 'Warm milk', 'Hamsters', 'Shovels', 'Air conditioning'],
  },
  {
    content: 'No bedroom is complete without _.',
    words: ['Mistakes', 'Test papers', 'Colouring books', 'Lava lamps', 'Quilts'],
  },
  {
    content: '_ in a box is the perfect gift this festive season.',
    words: ['Frankfurters', 'Bees', 'Limp carrots', 'Crows', 'Trout'],
  },
  {
    content: 'Doctors have warned of a new virus that is spread by _.',
    words: ['Hugs', 'Action figures', 'Positive thinking', 'Pajamas', 'Insults'],
  },
  {
    content: 'Every good adventure starts with _.',
    words: ['Playing cards', 'Cheese boards', 'Betrayal', 'Roll call', 'Dinner'],
  },
  {
    content: 'In art class, all I ever wanted to draw was _.',
    words: ['Trombones', 'Stale sandwiches', 'Glass houses', 'Happy trees', 'Utopia'],
  },
  {
    content: 'I expected gold but found _ at the end of the rainbow.',
    words: ['Yams', 'Forgiveness', 'Graffiti', 'Pizza', 'Ferrets'],
  },
  {
    content: '_ kept me alive when I was lost in the wilderness.',
    words: ['My little fingers', 'Slimy tentacles', 'Blurry memes', 'Faith', 'Mulch'],
  },
  {
    content: 'I won photograph of the year for snapping pictures of _.',
    words: ['Chubby toddlers', 'Friendly ghosts', 'Insurance claims', 'Toilets', 'Cameras'],
  },
];

export interface PlayingCard {
  content: string;
  words: string[];
}

const getRandomPlayingCards = (n = 1): PlayingCard[] => {
  const result = new Array(n);
  let len = PLAYING_CARDS.length;
  const taken = new Array(len);
  if (n > len)
    throw new RangeError(
      `getRandom: more elements taken than available.
  Most likely issue with the amount of rounds in the config.`
    );
  while (n--) {
    const x = Math.floor(Math.random() * len);
    result[n] = PLAYING_CARDS[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
};

export default getRandomPlayingCards;
