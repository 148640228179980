import axios from 'axios';
import { EventEmitter } from 'events';
import { createEventFn } from './events';
import { connectSocket, sendToSocket, sendToSocketAsync, SocketConnectionData } from './socket';
import { assertIsDefined, assertNever } from '@magicyard/utils/typeUtils';
import { CONFIG, objectKeys } from '@magicyard/utils';
import { BaseController } from '../hooks/usePlatformControllerTypes';
import { Socket } from 'socket.io-client';
import { randomChoice } from '@magicyard/utils/numberUtils';

// HTTP and WS are coupled (same host) right now, if necessary they can become two separate vars.
const BASE_URL = CONFIG.PLATFORM_SERVER_URL as string; // This is guaranteed to be a string by resolveURL(required = true)
const _baseUrlURL = new URL(BASE_URL);
const BASE_WEBSOCKET_URL = `${_baseUrlURL.protocol.endsWith('s:') ? 'wss:' : 'ws:'}//${_baseUrlURL.host}`;

export { BASE_URL, BASE_WEBSOCKET_URL };
const api = axios.create({
  baseURL: BASE_URL,
});

export interface Display {
  /**
   * The identifier of the Display.
   * Format: UUID4
   */
  id: string;
  code: string;
}

export interface DisplayDto {
  /**
   * The identifier of the Display.
   * Format: UUID4
   */
  id: string;
  code: string;
  yard: YardDto;
  game_start_args?: unknown;
}

export interface ControllerCreateInput {
  /**
   * The identifier of the controller
   * Format: UUID4
   */
  id?: string;
  /** The player's nickname */
  name: string;
  /** The player's avatar URL */
  avatar_url: string;
}

export interface Profile {
  id: string;
  name: string;
  avatarUrl: string;
}

export const controllerDtoToProfile = (controller: ControllerDtoBase): Profile => {
  return {
    id: controller.id,
    name: controller.name,
    avatarUrl: controller.avatar_url,
  };
};

const controllerDtoToBaseController = (controller: ControllerDtoBase): BaseController => {
  return {
    profile: controllerDtoToProfile(controller),
    isOnline: controller.is_online,
  };
};

// TODO this function is here because the backend may send a full yard on yardUpdate
// TODO this is bad code, and the backend should change to send the full yard only onJoinedYard
export const tryPartialYardDtoToYardUnsafe = (yardDto: Partial<YardDto>): CombinedYard | null => {
  if (yardDto.id === undefined) {
    return null;
  }
  const yardPart = {
    id: yardDto.id,
    controllers: yardDto.controllers?.map(controllerDtoToBaseController) ?? [],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    hostName: yardDto.host_name!,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    name: yardDto.name!,
    voiceChatState: yardDto.voice_chat_state ?? null,
    display_url: yardDto.display_url ?? null,
    displays: yardDto.displays ?? null,
    host: yardDto.host === undefined || yardDto.host === null ? null : controllerDtoToBaseController(yardDto.host),
  };

  if (yardDto.game_id !== null && yardDto.game_id !== undefined) {
    if (yardDto.queue_id !== null && yardDto.queue_id !== undefined) {
      return {
        type: 'withQueue',
        ...yardPart,
        gameId: yardDto.game_id,
        queue: { id: yardDto.queue_id, yards: [] },
      };
    }
    return {
      type: 'withGame',
      ...yardPart,
      gameId: yardDto.game_id,
    };
  }

  return {
    type: 'basic',
    ...yardPart,
  };
};
export const yardDtoToYard = (yardDto: YardDto): CombinedYard => {
  const yard = tryPartialYardDtoToYardUnsafe(yardDto);
  assertIsDefined(yard);
  return yard;
};

export const yardDtoToYardWithGameUnsafe = (yardDto: YardDto): YardWithGame => {
  const yard = yardDtoToYard(yardDto);
  switch (yard.type) {
    case 'basic':
      throw new Error('Unsafe conversion to gameYard failed');
    case 'withGame':
    case 'withQueue':
      return yardWithQueueToYardWithGame(yard);
    default:
      assertNever(yard);
  }
};

interface ControllerDtoBase {
  id: string;
  name: string;
  avatar_url: string;
  is_online: boolean;
}

export interface ControllerDto extends ControllerDtoBase {
  /**
   * The identifier of the display the controller is connected to if the
   * controller is connected to a display or null
   * Format: UUID4
   */
  display_id: string | null;

  yard: YardDto | null;

  game_start_args: unknown;
}

export interface ConnectDisplayWithIdInput {
  /** The ID of the display to connect to */
  display_id: string;
  /** Whether to join the (this) controller's yard (true) or the display's yard (false) */
  join_controller_yard?: boolean;
}

export interface ConnectDisplayWithCodeInput {
  /** The ID of the display to connect to */
  code: string;
  /** Whether to join the (this) controller's yard (true) or the display's yard (false) */
  join_controller_yard?: boolean;
}

export interface YardDto {
  /**
   * The identifier of the Yard.
   * Format: UUID4
   */
  id: string;
  /**
   * Yard's name, defaults to "${First controller}'s Yard"
   */
  name: string;
  /**
   * Yard's name, defaults to "${First controller}'s Yard"
   */
  host_name: string;
  /**
   * The controllers
   */
  controllers: ControllerDtoBase[];
  /**
   * The yard has voice chat enabled
   */
  voice_chat_state: VoiceChatState | null;
  /**
   * The url of currently displaying game
   */
  display_url: string | null;

  displays: Array<{ id: string; name: string | null }> | null;

  game_id: string | null;
  queue_id: string | null;
  game_starting: boolean;
  host: ControllerDtoBase | null;
}

export interface VoiceChatState {
  speakers: Array<{ id: string; muted: boolean }>;
}

interface BaseYard {
  id: string;
  name: string;
  hostName: string;
  controllers: BaseController[];
  voiceChatState: VoiceChatState | null;
  displays: Array<{ id: string; name: string | null }> | null;
  display_url: string | null;
  host: BaseController | null;
}

export interface Yard extends BaseYard {
  type: 'basic';
}

export interface YardWithGame extends BaseYard {
  type: 'withGame';
  gameId: string;
}

export interface YardWithQueue extends BaseYard {
  type: 'withQueue';
  queue: Queue;
  gameId: string;
}

export const yardWithQueueToYardWithGame = (yard: YardWithQueue | YardWithGame): YardWithGame => {
  if (yard.type === 'withGame') {
    return yard;
  }

  return {
    type: 'withGame',
    gameId: yard.gameId,
    controllers: yard.controllers,
    voiceChatState: yard.voiceChatState,
    hostName: yard.hostName,
    name: yard.name,
    id: yard.id,
    display_url: yard.display_url,
    displays: yard.displays,
    host: yard.host,
  };
};

export type CombinedYard = Yard | YardWithGame | YardWithQueue;

export interface Queue {
  id: string;
  yards: CombinedYard[];
}

export interface UpdateVoiceChatInput {
  /**
   * The identifier of the speaker controller, or null
   * Format: UUID4
   */
  force: boolean;
  muted: boolean;
}

export interface Reaction {
  /**
   * The identifier of the Reaction.
   * Format: UUID4
   */
  id: string;
  controller_id: string;
  payload: string;
}

export interface NavigationCommand {
  command: 'up' | 'down' | 'left' | 'right' | 'back' | 'enter' | 'toggleQr';
}

export interface JoinYardInput {
  yard_id: string;
}

export interface LeaveYardInput {
  yard_id: string;
}

export interface SendReactionInput {
  payload: string;
}

export interface GameConfigOverride {
  server?: string;
  controller?: string;
  display?: string;
}

export interface StartGameInput {
  game_id: string;
  game_config_urls_override?: GameConfigOverride;
  extras?: any;
}

export interface GameStartFailure {
  reason: string;
}

export interface JoinedQueue {
  id: string;
  yards: YardDto[];
}

export interface QueueUpdated {
  yards: YardDto[];
}

export const YARD_UPDATED = 'yard_updated' as const;
export const CONTROLLER_UPDATED = 'controller_updated' as const;
export const CONNECT_DISPLAY = 'connect_display' as const;
export const JOIN_YARD = 'join_yard' as const;
export const LEAVE_YARD = 'leave_yard' as const;
export const JOINED_YARD = 'joined_yard' as const;
export const UPDATE_VOICE_CHAT = 'update_voice_chat' as const;
export const LEAVE_VOICE_CHAT = 'leave_voice_chat' as const;
export const SEND_REACTION = 'send_reaction' as const;
export const REACTION_RECEIVED = 'reaction_received' as const;
export const SEND_NAVIGATION_COMMAND = 'send_navigation_command' as const;
export const RECEIVED_NAVIGATION_COMMAND = 'received_navigation_command' as const;
export const START_GAME = 'start_game' as const;
export const END_GAME = 'end_game' as const;
export const START_ONLINE_GAME = 'start_online_game' as const;
export const JOINED_QUEUE = 'joined_queue' as const;
export const QUEUE_UPDATED = 'queue_updated' as const;
export const LEAVE_QUEUE = 'leave_queue' as const;
export const REHYDRATE = 'rehydrate' as const;
export const GAME_STARTED = 'game_started' as const;
export const GAME_ENDED = 'game_ended' as const;
export const GAME_STARTING = 'game_starting' as const;
export const GAME_START_FAILURE = 'game_start_failure' as const;
export const CONTROLLER_SOCKET_CHANGED = 'controller_socket_changed' as const;

const emitter = new EventEmitter();

let socket: Socket | null;

export interface CreateDisplayOptions {
  streamNeeded: boolean;
  yardGameId?: string;
  id?: string;
}

/** Create a display */
export async function createDisplay(createDisplayOptions: CreateDisplayOptions): Promise<DisplayDto> {
  const result = await api.post<DisplayDto>('/displays', {
    stream_needed: createDisplayOptions.streamNeeded,
    yard_game_id: createDisplayOptions.yardGameId,
    id: createDisplayOptions.id,
  });
  return result.data;
}

/** Get a display */
export async function getDisplay(id: string): Promise<DisplayDto> {
  const result = await api.get<DisplayDto>(`/displays/${id}`);
  return result.data;
}

/** Create a controller */
export async function createController(createInput: ControllerCreateInput): Promise<ControllerDto> {
  const result = await api.post<ControllerDto>('/controllers', createInput);
  return result.data;
}

/** Get a controller */
export async function getController(id: string): Promise<ControllerDto> {
  const result = await api.get<ControllerDto>(`/controllers/${id}`);
  return result.data;
}

export async function patchController(id: string, body: Partial<ControllerDto>): Promise<ControllerDto> {
  const result = await api.patch<ControllerDto>(`/controllers/${id}`, body);
  return result.data;
}

/** Connect controller/display to server */
export function connectToSocket({
  socketData,
  handleConnect,
  handleReconnecting,
}: {
  socketData: SocketConnectionData;
  handleConnect: () => void;
  handleReconnecting: () => void;
}): Socket {
  if (socket?.connected === true) {
    console.log('Socket still open, did not reconnect.');
    return socket;
  }
  socket = connectSocket(BASE_WEBSOCKET_URL, socketData, handleConnect, handleReconnecting);
  console.debug(`Connected to ${socketData.type} ${socketData.id}`);

  socket.on('disconnect', (reason) => {
    console.debug(`Controller socket disconnected because ${reason}`);
  });
  socket.on('action', (data: any) => {
    console.debug('Message from server ', data);
    const { event, data: eventData } = data;
    emitter.emit(event, eventData);
  });

  return socket;
}

/** Listen to player listen updates */
export const onControllerUpdated = createEventFn<Partial<ControllerDto>>(emitter, CONTROLLER_UPDATED);

/** Listen to player listen updates */
export const onYardUpdated = createEventFn<Partial<YardDto>>(emitter, YARD_UPDATED);

/** Listen to joining yard */
export const onJoinedYard = createEventFn<YardDto>(emitter, JOINED_YARD);

/** Listen to received reactions  */
export const onReactionReceived = createEventFn<Reaction>(emitter, REACTION_RECEIVED);
export const onNavigationCommandReceived = createEventFn<NavigationCommand>(emitter, RECEIVED_NAVIGATION_COMMAND);

/** Listen to game started events */
export const onGameStartedDisplay = createEventFn<DisplayDto>(emitter, GAME_STARTED);
export const onGameStartedController = createEventFn<ControllerDto>(emitter, GAME_STARTED);

export const onGameEndedDisplay = createEventFn<DisplayDto>(emitter, GAME_ENDED);
export const onGameEndedController = createEventFn<ControllerDto>(emitter, GAME_ENDED);

// TODO may get who created the game / game name
export const onGameStarting = createEventFn<VoidFunction>(emitter, GAME_STARTING);

export const onGameStartFailure = createEventFn<GameStartFailure>(emitter, GAME_START_FAILURE);

export const onJoinedQueue = createEventFn<JoinedQueue>(emitter, JOINED_QUEUE);
export const onLeaveQueue = createEventFn<{}>(emitter, LEAVE_QUEUE);
export const onQueueUpdated = createEventFn<QueueUpdated>(emitter, QUEUE_UPDATED);

/** Join controller to yard */
export function joinYard(yardId: string): void {
  const data: JoinYardInput = { yard_id: yardId };
  sendToSocketWrapper(JOIN_YARD, data);
}

/** Leave yard */
export function leaveYard(yardId: string): void {
  console.debug(`Leaving yard ${yardId}...`);
  const data: LeaveYardInput = { yard_id: yardId };
  sendToSocketWrapper(LEAVE_YARD, data);
}

export function updateYard(payload: { game_id?: string | null; display_url?: string | null }): void {
  sendToSocketWrapper('update_yard', payload);
}

/** Join display to yard */
export function displayJoinYard(yardId: string): void {
  const data: JoinYardInput = { yard_id: yardId };
  sendToSocketWrapper(JOIN_YARD, data);
}

export async function connectToDisplay(controllerId: string, displayId: string) {
  const connectDisplayData = { id: displayId };
  const result = await api.put<ControllerDto>(`controllers/${controllerId}/display`, connectDisplayData);
  return result.data;
}

/** Connect controller to display */
export function connectToDisplayWithIdSocket(displayId: string, joinControllerYard = false): void {
  const data: ConnectDisplayWithIdInput = {
    display_id: displayId,
    join_controller_yard: joinControllerYard,
  };
  sendToSocketWrapper(CONNECT_DISPLAY, data);
}

export function connectToDisplayWithCode(code: string, joinControllerYard = false): void {
  const data: ConnectDisplayWithCodeInput = {
    code: code,
    join_controller_yard: joinControllerYard,
  };
  sendToSocketWrapper(CONNECT_DISPLAY, data);
}

/** Send a reaction */
export function sendReaction(payload: string): void {
  const data: SendReactionInput = { payload: payload };
  sendToSocketWrapper(SEND_REACTION, data);
}

export function sendNavigationCommand(cmd: NavigationCommand): void {
  sendToSocketWrapper(SEND_NAVIGATION_COMMAND, { payload: cmd });
}

export function joinVoiceChat(): void {
  updateVoiceChat(false, false);
}

export function leaveVoiceChat(): void {
  sendToSocketWrapper(LEAVE_VOICE_CHAT);
}

export function grabVoiceChat(): void {
  updateVoiceChat(false, true);
}

export function updateMuteStateVoiceChat(muted: boolean) {
  updateVoiceChat(muted, false);
}

/** Become the speaker controller */
function updateVoiceChat(muted: boolean, force?: boolean): void {
  const input: UpdateVoiceChatInput = { muted: muted, force: force ?? false };
  sendToSocketWrapper(UPDATE_VOICE_CHAT, input);
}

function getGameConfigOverride(): { game_config_urls_override: GameConfigOverride } | undefined {
  const gameConfigOverride: GameConfigOverride = {};
  if (CONFIG.MGY_ENV !== 'production') {
    if (typeof CONFIG.GAME_START_SERVER_URL === 'string' && CONFIG.GAME_START_SERVER_URL.startsWith('http')) {
      gameConfigOverride.server = CONFIG.GAME_START_SERVER_URL;
    }
    if (typeof CONFIG.GAME_START_CONTROLLER_URL === 'string' && CONFIG.GAME_START_CONTROLLER_URL.startsWith('http')) {
      gameConfigOverride.controller = CONFIG.GAME_START_CONTROLLER_URL;
    }
    if (typeof CONFIG.GAME_START_DISPLAY_URL === 'string' && CONFIG.GAME_START_DISPLAY_URL.startsWith('http')) {
      gameConfigOverride.display = CONFIG.GAME_START_DISPLAY_URL;
    }
  }
  console.log({ gameConfigOverride: gameConfigOverride });
  return objectKeys(gameConfigOverride).length === 0 ? undefined : { game_config_urls_override: gameConfigOverride };
}

/** Start the given game ID */
export function startGame(gameId: string, extras?: any): void {
  const input: StartGameInput = { game_id: gameId, extras: extras, ...getGameConfigOverride() };
  sendToSocketWrapper(START_GAME, input);
}

export function endGame(resetGameId?: boolean): void {
  sendToSocketWrapper(END_GAME, { reset_game_id: resetGameId ?? false });
}

export function startOnlineGame(gameId: string): void {
  const input: StartGameInput = { game_id: gameId, ...getGameConfigOverride() };
  sendToSocketWrapper(START_ONLINE_GAME, input);
}

export function leaveQueue(queueId: string): void {
  const input = { queue_id: queueId };
  sendToSocketWrapper(LEAVE_QUEUE, input);
}

/** Rehydrate controller */
export function rehydrate() {
  sendToSocketWrapper(REHYDRATE);
}

// TODO debug, not yet possible ot use
export async function rehydrateAsync() {
  return await sendToSocketAsyncWrapper<ControllerDto>(REHYDRATE);
}

/** Whether the API returned a not found error */
export function isNotFoundError(error: Error): boolean {
  return axios.isAxiosError(error) && error.response?.status === 404;
}

function sendToSocketWrapper(event: string, data?: unknown) {
  if (socket == null) {
    throw new Error('socket is not connected');
  }
  sendToSocket(socket, event, data);
}

// TODO debug, not yet possible ot use
async function sendToSocketAsyncWrapper<T>(event: string, data?: unknown) {
  if (socket == null) {
    throw new Error('socket is not connected');
  }
  return await sendToSocketAsync<T>(socket, event, data);
}

export const getYardAfterMutation = (yardDto: Partial<YardDto>, oldYard: CombinedYard | null): CombinedYard => {
  if (oldYard === null) {
    const tryPartial = tryPartialYardDtoToYardUnsafe(yardDto);
    assertIsDefined(tryPartial, "Impossible to mutate yard that doesn't exist");
    return tryPartial;
  }

  return yardDtoToYard({ ...yardToYardDto(oldYard), ...yardDto });
};

export const yardToYardDto = (yard: CombinedYard): YardDto => {
  const baseDto: YardDto = {
    id: yard.id,
    name: yard.name,
    host_name: yard.hostName,
    controllers: yard.controllers.map(baseControllerToControllerDTO),
    voice_chat_state: yard.voiceChatState,
    display_url: yard.display_url,
    game_id: null,
    queue_id: null,
    displays: yard.displays,
    game_starting: false,
    host: yard.host === null ? null : baseControllerToControllerDTO(yard.host),
  };
  switch (yard.type) {
    case 'basic':
      break;
    case 'withGame':
      baseDto.game_id = yard.gameId;
      break;
    case 'withQueue':
      baseDto.game_id = yard.gameId;
      baseDto.queue_id = yard.queue.id;
      break;
    default:
      assertNever(yard);
  }

  return baseDto;
};

const baseControllerToControllerDTO = (controller: BaseController): ControllerDtoBase => {
  return {
    avatar_url: controller.profile.avatarUrl,
    id: controller.profile.id,
    name: controller.profile.name,
    is_online: controller.isOnline,
  };
};

export const wasInvitedToNewYard = (newYard: CombinedYard, oldYard: CombinedYard) => newYard.id !== oldYard.id;

export const generateRandomControllers = (n: number): BaseController[] => {
  const arr: BaseController[] = [];
  for (let i = 0; i < n; i++) {
    arr.push({
      profile: {
        name: generateRandomDefaultName(),
        avatarUrl: 'https://thispersondoesnotexist.com/',
        id: `${i}`,
      },
      isOnline: Math.random() > 0.5,
    });
  }

  return arr;
};

export const generateRandomDefaultName = () => {
  const firstNames = [
    'Red',
    'Green',
    'Blue',
    'Purple',
    'Big',
    'Small',
    'Average',
    'Huge',
    'Tiny',
    'Old',
    'Young',
    'Tall',
    'Short',
    'Sleepy',
    'Rude',
    'Spicy',
  ];
  const lastNames = [
    'Banana',
    'Cherry',
    'Pear',
    'Mandarin',
    'Apricot',
    'Melon',
    'Grape',
    'Lemon',
    'Plum',
    'Mango',
    'Kiwi',
    'Apple',
    'Papaya',
  ];
  return `${randomChoice(firstNames)} ${randomChoice(lastNames)}`;
};
