import React, { useEffect, useState } from 'react';
import { useGameObject } from '../../../store/GameContext';
import { ProgressBarDrain } from '../../../components/Progress/ProgressBarDrain';
import { Phases } from '@magicyard/blanksy-game/src/Game';
import { StageRoot } from '../StageRoot';
import { DrawingsPart } from '../DrawingsPart';

const PAGE_INFO = {
  dictionary: {
    title: 'Get Ready!',
    info_title: 'Guess as many drawings as you can',
    info_secondary: 'The same input line is used for all drawings',
  },
};

const DisplayGuessWord = () => {
  const [hintLevel, setHintLevel] = useState(-1);

  const { G, ctx } = useGameObject();
  const { roundDrawings, currentRoundNumber, players } = G;

  useEffect(() => {
    let hintTimeout = setTimeout(() => {
      if (hintLevel > 3) {
        clearTimeout(hintTimeout);
      } else {
        const newHintLevel = hintLevel + 1;
        setHintLevel(newHintLevel);
      }
    }, 7000);

    return () => {
      clearTimeout(hintTimeout);
    };
  }, [hintLevel]);

  const drawings = players.map((player) => {
    const drawing = roundDrawings[currentRoundNumber][player.id];
    return {
      playerID: player.id,
      strokes: drawing !== undefined ? drawing.strokes : [],
      word: drawing !== undefined ? drawing.word : 'Did not draw',
      bounds:
        drawing !== undefined
          ? drawing.bounds
          : {
              maxX: 0,
              maxY: 0,
            },
    };
  });

  return (
    <>
      <StageRoot title={'Guess the words!'} sentence={G.cardSentences[G.currentRoundNumber].content}>
        <DrawingsPart drawings={drawings} showHints={true} showWord={false} />
      </StageRoot>
      <ProgressBarDrain phase={ctx.phase as Phases} phaseStartTime={G.phaseStartTime} />
    </>
  );
};

export default DisplayGuessWord;
