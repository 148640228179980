import React, { useCallback, useEffect, useState } from 'react';
import { Phases } from '@magicyard/blanksy-game/src/Game';
import WelcomeAudio from '@magicyard/blanksy-shared/src/assets/audio/00_welcome.mp3';
import WordChoosingAudio from '@magicyard/blanksy-shared/src/assets/audio/01_word-choosing.mp3';
import DrawingAudio from '@magicyard/blanksy-shared/src/assets/audio/02_drawing.mp3';
import GuessingAudio from '@magicyard/blanksy-shared/src/assets/audio/03_guessing.mp3';
import VotingAudio from '@magicyard/blanksy-shared/src/assets/audio/04_vote.mp3';
import IntroAudio from '../assets/audio/qr-screen-intro.mp3';
import { useNativeFocus } from '@magicyard/shared/src/UseNativeFocus';

const newMusic = (src: string | undefined) => {
  if (DISABLE_AUDIO) {
    return null;
  }
  const newAudio = new Audio(src);
  newAudio.loop = true;
  return newAudio;
};

const searchParams = new URLSearchParams(window.location.search);
export const DISABLE_AUDIO = searchParams.get('version_name') === 'LG' || searchParams.get('disable_sound') === '1';

export const AppIntroAudio = DISABLE_AUDIO ? null : new Audio(IntroAudio);

export const AudioStagesMap: { [key in Phases]: HTMLAudioElement | null } = {
  [Phases.Sync]: null,
  [Phases.Explainer]: null,
  [Phases.Initial]: newMusic(WordChoosingAudio),
  [Phases.First]: newMusic(DrawingAudio),
  [Phases.Second]: newMusic(GuessingAudio),
  [Phases.Third]: newMusic(VotingAudio),
  [Phases.Fourth]: newMusic(VotingAudio),
  [Phases.EndRound]: newMusic(WelcomeAudio),
  [Phases.GameEnd]: newMusic(WelcomeAudio),
};

const AudioManager = ({ phase }: { phase: Phases }) => {
  const [activeSoundTrack, setActiveSoundTrack] = useState<Phases | null>(null);
  const [showAudioEnabler, setShowAudioEnabler] = useState(false);
  const [isInFocus, setIsInFocus] = useState(true);

  const onBlur = useCallback(() => {
    setIsInFocus(false);
    AudioStagesMap[phase]?.pause();
  }, [phase]);
  const onFocus = useCallback(() => {
    if (AudioStagesMap[phase]?.paused) {
      setIsInFocus(true);
      AudioStagesMap[phase]?.play();
    }
  }, [phase]);

  useNativeFocus(onFocus, onBlur);
  const playAudioIfFocused = useCallback(() => {
    setShowAudioEnabler(false);
    const curr = AudioStagesMap[phase];
    if (isInFocus && curr !== null) {
      return curr.play();
    } else {
      return new Promise(() => undefined);
    }
  }, [isInFocus, phase]);

  const turnVolumeDown = useCallback((target: Phases) => {
    const curr = AudioStagesMap[target];
    if (curr === null) {
      return;
    }

    curr.volume = 0.5;
    setTimeout(() => {
      curr.volume = 0.2;
    }, 700);

    setTimeout(() => {
      curr.pause();
      curr.currentTime = 0;
      curr.volume = 0.5;
    }, 1500);
  }, []);

  useEffect(() => {
    const shouldPlayNewTrack = activeSoundTrack !== phase && isInFocus;
    const shouldStopOldTrack = shouldPlayNewTrack && activeSoundTrack !== null;
    if (shouldStopOldTrack) {
      try {
        turnVolumeDown(activeSoundTrack);
      } catch (err) {
        console.log(err);
      }
    }
    if (shouldPlayNewTrack) {
      setActiveSoundTrack(phase);
    }
  }, [activeSoundTrack, isInFocus, phase, turnVolumeDown]);

  useEffect(() => {
    if (AudioStagesMap[phase]) {
      playAudioIfFocused()
        .then(() => {
          setShowAudioEnabler(false);
        })
        .catch(() => {
          setShowAudioEnabler(true);
        });
    } else console.log('Could Not find sound track');
  }, [activeSoundTrack, playAudioIfFocused, phase]);

  if (showAudioEnabler) {
    return (
      <button
        id="audio-manager--init"
        style={{ position: 'absolute', top: 10, left: 10, zIndex: 99999 }}
        onClick={playAudioIfFocused}
      >
        Enable Audio
      </button>
    );
  }

  return null;
};

export const stopMusic = () => {
  Object.values(AudioStagesMap).map((x) => x?.pause());
  AppIntroAudio?.pause();
};

export default AudioManager;
