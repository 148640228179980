import React, { useEffect } from 'react';
import { Phases } from '@magicyard/blanksy-game/src/Game';

import { Background } from '../components/Background/Background';
import { useGameObject } from '../store/GameContext';

import ActiveDrawing from './Stages/ActiveDrawing/DisplayActiveDrawingSimultaneous';
import DisplayGuessWord from './Stages/ActiveDrawing/DisplayGuessWord';
import PreGameCompleteSentence from './Stages/PreGameCompleteSentence';
import DisplayDrawingVote from './Stages/ActiveDrawing/DisplayDrawingVote';
import DisplayVoteSummary from './Stages/DisplayVoting/DisplayVoteSummary';
import AudioManager from '../AudioManager';
import { TransitionContainer } from '../components/Transition/TransitionContainer';
import fillBlanks from '../assets/transition/fill-blanks-title.webp';
import timeToDraw from '../assets/transition/time-to-draw-title.webp';
import timeToGuess from '../assets/transition/time-to-guess-title.webp';
import timeToVoteGrouped from '../assets/transition/time-to-vote-grouped.webp';
import { HowToWin } from './Stages/HowToWin';
import { QrSign } from '../QrSign';
import { WinnerTransition } from './Stages/WinnerTransition';
import { ScoreSummary } from './Stages/ScoreSummary';
import { GameEnd } from './Stages/GameEnd';
import { HowToPlay } from './Stages/HowToPlay';
import { getPhaseEventName, getTransitionPhaseEventName } from '@magicyard/blanksy-shared/analytics';
import { track, updateAnalyticsData } from '@magicyard/shared/src/analytics';
import { Title } from './Title';
import { Display } from '@magicyard/shared/platform/lib/api';

interface PhaseDataPage {
  position: number;
  element: () => JSX.Element;
}

interface PhaseData {
  mainPage: PhaseDataPage;
  transition: PhaseDataPage | null;
  hasQr: boolean;
}

const Sync = () => {
  const { moves } = useGameObject();
  useEffect(() => {
    moves.endSync();
  }, []);
  return (
    <Title
      title={'Syncing..'}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    />
  );
};

const phaseToScreen: Record<Phases, PhaseData> = {
  [Phases.Sync]: {
    mainPage: {
      element: Sync,
      position: 1,
    },
    transition: null,
    hasQr: false,
  },
  [Phases.Explainer]: {
    mainPage: { element: HowToWin, position: 0 },
    transition: { element: HowToPlay, position: 2 },
    hasQr: false,
  },
  [Phases.Initial]: {
    mainPage: { element: PreGameCompleteSentence, position: 0 },
    transition: {
      position: 1,
      element: () => (
        <TransitionContainer
          attentionBody={'You will need to draw it.\n Be funny you get extra points!'}
          attentionTitle={'CHOOSE CAREFULLY'}
          titleImageUrl={fillBlanks}
        />
      ),
    },
    hasQr: true,
  },
  [Phases.First]: {
    mainPage: { element: ActiveDrawing, position: 0 },
    transition: {
      position: 1,
      element: () => (
        <TransitionContainer
          attentionBody={'Players will need to guess your drawing'}
          attentionTitle={'BE CLEAR'}
          titleImageUrl={timeToDraw}
        />
      ),
    },
    hasQr: true,
  },
  [Phases.Second]: {
    mainPage: { element: DisplayGuessWord, position: 0 },
    transition: {
      position: 1,
      element: () => (
        <TransitionContainer
          attentionBody={'You get extra points for guessing words first'}
          attentionTitle={'BE QUICK'}
          titleImageUrl={timeToGuess}
        />
      ),
    },
    hasQr: true,
  },
  [Phases.Third]: {
    mainPage: { element: DisplayDrawingVote, position: 0 },
    transition: {
      position: 1,
      element: () => (
        <TransitionContainer
          attentionBody={'You cant vote for yourself'}
          attentionTitle={'BE GENEROUS'}
          titleImageUrl={timeToVoteGrouped}
        />
      ),
    },
    hasQr: true,
  },
  [Phases.Fourth]: { mainPage: { element: DisplayVoteSummary, position: 0 }, transition: null, hasQr: true },
  [Phases.EndRound]: { mainPage: { element: ScoreSummary, position: 2 }, transition: null, hasQr: true },
  [Phases.GameEnd]: {
    mainPage: { element: GameEnd, position: 2 },
    transition: { position: 1, element: WinnerTransition },
    hasQr: false,
  },
};

const StageManager = (props: { display?: Display }) => {
  const { ctx, G } = useGameObject();
  const { currentRoundNumber, isTransition } = G;
  const phase = ctx.phase as Phases;
  const curr = phaseToScreen[phase];

  useEffect(() => {
    updateAnalyticsData({ currentStage: phase, currentRound: currentRoundNumber });
    const eventName = isTransition && curr.transition ? getTransitionPhaseEventName(phase) : getPhaseEventName(phase);

    if (!eventName) {
      return;
    }
    track(eventName);
  }, [phase, currentRoundNumber, isTransition, curr]);

  return (
    <>
      <AudioManager phase={phase} />
      <Background
        camPosition={G.isTransition && curr.transition !== null ? curr.transition.position : curr.mainPage.position}
      >
        {G.isTransition && curr.transition !== null ? (
          <curr.transition.element />
        ) : (
          <>
            {curr.hasQr && props.display !== undefined ? <QrSign display={props.display} /> : undefined}
            <curr.mainPage.element key={ctx.phase} />
          </>
        )}
      </Background>
    </>
  );
};

export default StageManager;
