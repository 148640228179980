import React from 'react';
import './InputMasked.css';

const InputMasked = ({ word = '', isVisible = false, input = '' }) => {
  const WordMask = () => {
    const wordLengthArray = new Array(word.length).fill('');

    return (
      <>
        {wordLengthArray.map((x, idx) =>
          word[idx] === ' ' ? (
            <span className={'text--red'}>{input[idx] || '\u00A0\u00A0'}</span>
          ) : (
            <span className={'code-digit d-inline-block mx-2'} key={`dash-${idx}`} style={{ minWidth: 18 }}>
              {input[idx] || '\u00A0'}
            </span>
          )
        )}
      </>
    );
  };

  return (
    <>
      <div>
        {word ? (
          isVisible ? (
            <span className={'input-masked_visible-word'}>{word}</span>
          ) : (
            <WordMask />
          )
        ) : (
          <span className={'code-digit d-inline-block'} style={{ width: 120 }} />
        )}
      </div>
    </>
  );
};

export default InputMasked;
