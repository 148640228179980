import IntroAudio from '../../assets/audio/how-to-play-full.mp3';
import React, { useRef } from 'react';
import { Rat } from '../../components/Rat';
import './HowToPlay.css';
import { Title } from '../Title';
import { useGameObject } from '../../store/GameContext';
import {
  getServerTimePassedMs,
  useMillisReachedFromServer,
  useServerAudio,
} from '@magicyard/blanksy-shared/hooks/IntervalHooks';
import { Phases } from '@magicyard/blanksy-game/src/Game';
import { DISABLE_AUDIO } from '../../AudioManager';

const FINAL_STEP = 6;
const stepToTime = (step: number) => {
  let timeAcc = 0;

  if (step >= 1) {
    timeAcc += 6400;
  }
  if (step >= 2) {
    timeAcc += 3700;
  }
  if (step >= 3) {
    timeAcc += 1500;
  }
  if (step >= 4) {
    timeAcc += 2000;
  }
  if (step >= 5) {
    timeAcc += 3500;
  }
  if (step >= 6) {
    timeAcc += 1500;
  }

  return timeAcc;
};

export const HowToPlay = () => {
  const [step, setStep] = React.useState(0);
  const { G, ctx } = useGameObject();
  const x: Record<number, () => void> = {};
  for (let i = 0; i <= FINAL_STEP; i++) {
    x[stepToTime(i)] = () => setStep(i + 1);
  }

  useMillisReachedFromServer({
    serverTimePassedMs: getServerTimePassedMs(G.phaseStartTime, ctx.phase as Phases, true),
    millisWithCallback: x,
  });

  const introMusic = useRef(DISABLE_AUDIO ? null : new Audio(IntroAudio));
  useServerAudio({
    audio: introMusic.current,
    phaseStartTime: G.phaseStartTime,
    phase: ctx.phase as Phases,
    isTransition: true,
  });

  return (
    <div className={'how-to-play_root'}>
      {/* Path defintion for curve text*/}
      <svg style={{ position: 'absolute' }}>
        <path id="MyPath" fill="none" stroke="transparent" d="m10,45c53,-38 88,7 88,10" />
        <path id="MyPath2" fill="none" stroke="transparent" d="m13,45c53,-38 88,7 88,10" />
        <path id="MyPath3" fill="none" stroke="transparent" d="m10,45c53,50 88,7 88,10" />
        <path id="MyPath4" fill="none" stroke="transparent" d="m12,45c53,50 88,7 88,10" />
      </svg>
      {step === 1 && <Title className={'how-to-play_title'} title={'Welcome to blanksy!'} />}
      {step >= 3 && <Title className={'how-to-play_title'} title={'how it works'} />}
      {step >= 1 && <Rat className={`how-to-play_rat`} mirror={true} variant={'announcer'} />}
      {step >= 2 && <div className={'how-to-play_arty'}>Arty</div>}
      {step >= 4 && (
        <div className={'how-to-play_fill-blanks-container how-to-play_font-override'}>
          <div className={'how-to-play_anchor'}>
            <div className={'how-to-play_fill-blanks-background how-to-play_backstain-appear-anim'} />
            <div className={'how-to-play_fill-blanks-icon how-to-play_icon-appear-anim'} />
            <div className={'how-to-play_title-fill-blanks how-to-play_title-appear-anim'}>
              <svg viewBox="0 0 110 110">
                <text>
                  <textPath fill={'#e09cc0'} href="#MyPath">
                    Fill in blanks
                  </textPath>
                  <textPath fill={'black'} href="#MyPath2">
                    Fill in blanks
                  </textPath>
                </text>
              </svg>
            </div>
          </div>
        </div>
      )}
      {step >= 5 && (
        <div className={'how-to-play_draw-container how-to-play_font-override'}>
          <div className={'how-to-play_anchor'}>
            <div className={'how-to-play_title-draw how-to-play_title-appear-anim'}>
              <svg viewBox="0 0 110 110">
                <text>
                  <textPath fill={'#e09cc0'} href="#MyPath3">
                    Draw
                  </textPath>
                  <textPath fill={'black'} href="#MyPath4">
                    draw
                  </textPath>
                </text>
              </svg>
            </div>
            <div className={'how-to-play_draw-background how-to-play_backstain-appear-anim'} />
            <div className={'how-to-play_draw-icon how-to-play_icon-appear-anim'} />
          </div>
        </div>
      )}
      {step >= 6 && (
        <div className={'how-to-play_guess-container how-to-play_font-override'}>
          <div className={'how-to-play_anchor'}>
            <div className={'how-to-play_guess-background how-to-play_backstain-appear-anim'} />
            <div className={'how-to-play_guess-icon how-to-play_icon-appear-anim'} />
            <div className={'how-to-play_title-guess how-to-play_title-appear-anim'}>
              <svg viewBox="0 0 110 110">
                <text>
                  <textPath fill={'#e09cc0'} href="#MyPath">
                    guess
                  </textPath>
                  <textPath fill={'black'} href="#MyPath2">
                    guess
                  </textPath>
                </text>
              </svg>
            </div>
          </div>
        </div>
      )}
      {step >= 7 && (
        <div className={'how-to-play_vote-container how-to-play_font-override'}>
          <div className={'how-to-play_anchor'}>
            <div className={'how-to-play_title-vote how-to-play_title-appear-anim'}>
              <svg viewBox="0 0 110 110">
                <text>
                  <textPath fill={'#e09cc0'} href="#MyPath3">
                    vote
                  </textPath>
                  <textPath fill={'black'} href="#MyPath4">
                    vote
                  </textPath>
                </text>
              </svg>
            </div>
            <div className={'how-to-play_vote-background how-to-play_backstain-appear-anim'} />
            <div className={'how-to-play_vote-icon how-to-play_icon-appear-anim'} />
          </div>
        </div>
      )}
    </div>
  );
};
