import { useGameObject } from '../../store/GameContext';
import React from 'react';
import './WinnerTransition.css';
import { GetRoundWinners } from '../../components/Standings/utils/StandingsUtil';
import { PlayerPart } from '../PlayerPart';
import { Title } from '../Title';
import { Crown } from '../../components/Crown';

export const WinnerTransition = () => {
  const { G } = useGameObject();
  const { players } = G;
  const { sortedLeaderBoard } = GetRoundWinners();
  const [winnerItem] = sortedLeaderBoard;
  const winner = winnerItem.playerID;
  const roundWinnerEntry = players[+winner];

  return (
    <div className={'winner-transition_root'}>
      <Title title={'And the winner is'} />
      <div className={'winner-transition_confetti'} />
      <Crown variant={'dark'} className={'winner-transition_crown'} />
      <div className={'winner-transition_single-frame'}>
        <PlayerPart
          index={0}
          isVisible={true}
          player={roundWinnerEntry}
          containerClassName={'winner-transition_player'}
        />
        <div className={'winner-transition_medal'} />
      </div>
    </div>
  );
};
