import { useCallback, useEffect, useRef } from 'react';
import { assertNever } from '@magicyard/utils/typeUtils';

export type NativeMessages = 'nativeFocusIn' | 'nativeFocusOut';

// TODO there's a small chance for a bug here, if before the component mounts the focus is false it will still play the music
// TODO The right choice here might be to go with a focus context and use NativeContext will just listen to changes on that
export let lastFocus = true;
/**
 * Will initially call the function matching the last focus state.
 * On unmount will call blur.
 * @param onFocus
 * @param onBlur
 */
export const useNativeFocus = (onFocus: () => void, onBlur: () => void) => {
  const lastLocalFocusState = useRef(lastFocus);
  const handleOnFocus = useCallback(() => {
    if (!lastLocalFocusState.current) {
      onFocus();
    }
    lastFocus = true;
    lastLocalFocusState.current = true;
  }, [onFocus]);

  const handleOnBlur = useCallback(() => {
    if (lastLocalFocusState.current) {
      onBlur();
    }
    lastFocus = false;
    lastLocalFocusState.current = false;
  }, [onBlur]);

  // Catch up with global focus state
  useEffect(() => {
    if (lastFocus) {
      onFocus();
    } else {
      onBlur();
    }
  }, []);

  useEffect(() => {
    const messageHandler = (e: MessageEvent<NativeMessages>) => {
      if (e.origin !== window.location.origin || typeof e.data !== 'string') {
        return;
      }
      switch (e.data) {
        case 'nativeFocusIn':
          handleOnFocus();
          break;
        case 'nativeFocusOut':
          handleOnBlur();
          break;
        default:
          console.log('AudioManager ignoring: ' + assertNever(e.data, true));
      }
    };

    const visChange = () => {
      if (document.visibilityState === 'visible') {
        handleOnFocus();
      } else {
        handleOnBlur();
      }
    };

    window.addEventListener('message', messageHandler);
    document.addEventListener('visibilitychange', visChange);
    window.addEventListener('blur', handleOnBlur);
    window.addEventListener('focus', handleOnFocus);

    return () => {
      window.removeEventListener('message', messageHandler);
      document.removeEventListener('visibilitychange', visChange);
      window.removeEventListener('blur', handleOnBlur);
      window.removeEventListener('focus', handleOnFocus);
    };
  }, [handleOnBlur, handleOnFocus]);
};
