import { getControllerUrlConnectDisplay } from '@magicyard/utils';
import { CombinedYard, Display, Profile } from '@magicyard/shared/platform/lib/api';
import { URL_SHORTENING_API } from '@magicyard/shared/platform/lib/urlShortener';

import React, { useEffect, useRef } from 'react';
import './YardAndDisplay.css';
import { Rat } from './components/Rat';
import { Crown } from './components/Crown';
import { track, updateAnalyticsData } from '@magicyard/shared/src/analytics';
import { AskToUsePhoneOverlay } from './AskToUsePhoneOverlay';
import dummyAvatar from './assets/queue/avatar.png';
import { BaseController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';

interface YardAndDisplayProps {
  yard: CombinedYard;
  display: Display;
  onClick: () => void;
  clickRequiredBeforeStart: boolean;
  url: string;
}

export const YardAndDisplay = ({ url, yard, display, clickRequiredBeforeStart, onClick }: YardAndDisplayProps) => {
  useEffect(() => {
    updateAnalyticsData({ displayId: display.id, yardId: yard.id });
  }, [display, yard]);

  useEffect(() => {
    if (!clickRequiredBeforeStart) {
      track('Yard And QR Loaded');
    }
  }, [clickRequiredBeforeStart]);

  const isEmptyYard = yard.controllers.length === 0;

  useEffect(() => {
    if (!clickRequiredBeforeStart && !isEmptyYard) {
      updateAnalyticsData({ numOfPlayers: yard.controllers.length });
      track('Yard With Players Loaded');
    }
  }, [yard.controllers, clickRequiredBeforeStart, isEmptyYard]);
  const dummies = Array(5 - yard.controllers.length)
    .fill(0)
    .map<BaseController>((_, idx) => ({
      profile: {
        id: '' + idx,
        name: '',
        avatarUrl: dummyAvatar,
      },
      holdingMicState: null,
      dummyData: { isOptional: yard.controllers.length + idx >= 3 },
      isOnline: true,
    }));
  const allPlayersWithDummies = yard.controllers.concat(dummies);
  return (
    <>
      <AskToUsePhoneOverlay />
      <div className={'yard-and-display_root'}>
        <div className={'yard-and-display_title'}>BLANKSY</div>
        <div className={'yard-and-display_subtitle'}>Scan to join!</div>
        <div className={'yard-and-display_white-spray'} />
        <div className={'yard-and-display_qr-container'}>
          <div className={'yard-and-display_qr-child-container'}>
            {yard.controllers.length < 5 && (
              <>
                <div
                  className={'yard-and-display_qr'}
                  style={{
                    backgroundImage: `url(${URL_SHORTENING_API}/qr?text=${encodeURIComponent(
                      getControllerUrlConnectDisplay(display.id)
                    )})`,
                  }}
                />
                <div className={'yard-and-display_code-title'}>or go on your phone to:</div>
                <div className={'yard-and-display_code-link'}>{url}</div>
                <div className={'yard-and-display_code'}>room code: {display.code}</div>
              </>
            )}
          </div>
        </div>
        <Rat
          absolutePosition={{ bottom: 80 }}
          variant={'spray'}
          className={`yard-and-display_rat ${!isEmptyYard ? 'yard-and-display_rat-disappear' : ''}`}
        />
        <div className={`yard-and-display_emoji-loco ${!isEmptyYard ? 'yard-and-display_crown-disappear' : ''}`} />
        <Crown
          className={`yard-and-display_crown ${!isEmptyYard ? 'yard-and-display_crown-disappear' : ''}`}
          variant={'light'}
        />
        {isEmptyYard ? null : (
          <YardPart
            controllers={allPlayersWithDummies}
            className={'yard-and-display_yard-part'}
            startAnimating={false}
          />
        )}
      </div>
    </>
  );
};

export const YardPart = ({
  controllers,
  className,
}: {
  controllers: Array<BaseController & { dummyData?: { isOptional: boolean } }>;
  className?: string;
  startAnimating: boolean;
}) => {
  return (
    <div className={className}>
      {controllers.map((c, index) => {
        return (
          <div key={c.profile.id} style={{ position: 'absolute', ...indexToPosition(index), textAlign: 'center' }}>
            <PlayerSelfie profile={c.profile} dummyData={c.dummyData} />
          </div>
        );
      })}
    </div>
  );
};

export const PlayerSelfie = ({ profile, dummyData }: { profile: Profile; dummyData?: { isOptional: boolean } }) => {
  const delay = useRef(Math.random() * 3);
  return (
    <>
      <div
        className={`selfie animate-selfie ${dummyData?.isOptional === true ? 'selfie_optional' : ''}`}
        style={{ backgroundImage: `url(${profile.avatarUrl})`, animationDelay: delay + 's' }}
      />
      <div
        className={`selfie_name 
        ${dummyData !== undefined ? 'selfie_name-dummy ' : ''}
        ${dummyData?.isOptional === true ? 'selfie_name-optional' : ''}`}
      >
        {profile.name}
        <span className={dummyData !== undefined ? 'selfie_name-dummy-spacing' : ''}>
          {dummyData?.isOptional === true ? '\nOptional' : dummyData?.isOptional === false ? '\nPlayer' : ''}
        </span>
      </div>
    </>
  );
};

const indexToPosition = (index: number) => {
  const left = (index - 3) * 150 + 150;
  const top = index === 0 || index === 4 ? '30%' : index % 2 === 0 ? '20%' : '50%';
  return {
    left,
    top,
  };
};
