import React, { useState } from 'react';
import './QueueScreen.css';
import { QrSign } from './QrSign';
import useInterval from './hooks/useInterval';
import { YardPart } from './YardAndDisplay';
import dummyAvatar from './assets/queue/avatar.png';
import { YardWithQueue, Display } from '@magicyard/shared/platform/lib/api';
import { BaseController } from '@magicyard/shared/platform/hooks/usePlatformControllerTypes';

interface QueueScreenProps {
  yard: YardWithQueue;
  display: Display;
}

export const QueueScreen = ({ yard, display }: QueueScreenProps) => {
  const queue = yard.queue;
  const [dots, setDots] = useState('.');

  useInterval(() => {
    setDots((d) => (d.length === 3 ? '.' : d + '.'));
  }, 1000);

  const allPlayers = queue.yards.reduce<BaseController[]>((acc, yard) => acc.concat(yard.controllers), []);
  const waitingFor = 5 - allPlayers.length;

  const dummies = Array(waitingFor)
    .fill(0)
    .map<BaseController>((_, idx) => ({
      profile: {
        id: '' + idx,
        name: dots,
        avatarUrl: dummyAvatar,
      },
      dummyData: { isOptional: waitingFor - idx < 3 },
      holdingMicState: null,
      isOnline: true,
    }));
  const allPlayersWithDummies = allPlayers.concat(dummies);

  return (
    <div className={'queue-screen_root'}>
      <div className={'queue-screen_white-bg'} />
      <div className={'queue-screen_red-carpet'} />
      <div className={'queue-screen_red-rope'} />
      <div className={'queue-screen_red-rope-shade'} />
      <div className={'queue-screen_arrow'} />
      <QrSign display={display} className={'queue-screen_qr'} size={300} />
      <div className={'queue-screen_text-container'}>
        <div className={'queue-screen_queueing-title'}>Queuing up{dots}</div>
        <div className={'queue-screen_queueing-subtitle'}>
          finding {waitingFor} more {waitingFor === 1 ? 'player' : 'players'}
        </div>
      </div>
      <div className={'queue-screen_faces-container'}>
        <YardPart controllers={allPlayersWithDummies} key={1} startAnimating={true} />
      </div>
    </div>
  );
};
