import { Rat } from '../../components/Rat';
import React, { useEffect } from 'react';
import './GameEnd.css';
import { Title } from '../Title';
import { PlayerPart } from '../PlayerPart';
import { useGameObject } from '../../store/GameContext';
import { GetRoundWinners } from '../../components/Standings/utils/StandingsUtil';
import SentencePuzzle from '../../components/SentencePuzzle/SentencePuzzle';
import DrawingAlt from '@magicyard/blanksy-shared/components/DrawingAlt';
import { Drawing } from '@magicyard/blanksy-game/src/Types';

const INITIAL_WAIT_TIME = 1500;
const OFF_TIME = 3000;
const ON_TIME = 6000;

export const GameEnd = () => {
  const { G } = useGameObject();
  const { players, roundDrawings, cardSentences } = G;
  const { sortedLeaderBoard } = GetRoundWinners();
  const [winnerItem] = sortedLeaderBoard;
  const winner = winnerItem.playerID;
  const roundWinnerEntry = players[+winner];
  const [currentDrawing, setCurrentDrawing] = React.useState<(Drawing & { round: number }) | null | 'END'>(null);

  useEffect(() => {
    const creditDrawings = Object.values(roundDrawings).reduce<{ millis: number; callback: () => void }[]>(
      (acc, drawingForRound, idx) => {
        const drawing = drawingForRound[winner];
        const d = {
          playerID: winner,
          strokes: drawing !== undefined ? drawing.strokes : [],
          word: drawing !== undefined ? drawing.word : 'Did not draw',
          bounds:
            drawing !== undefined
              ? drawing.bounds
              : {
                  maxX: 0,
                  maxY: 0,
                },
        };
        acc.push({
          millis: idx * ON_TIME + (idx === 0 ? 0 : INITIAL_WAIT_TIME) + OFF_TIME,
          callback: () => {
            setCurrentDrawing({
              round: idx,
              ...d,
            });
          },
        });
        acc.push({
          millis: (idx + 1) * (ON_TIME + OFF_TIME / 2) + (idx === 0 ? INITIAL_WAIT_TIME : 0),
          callback: () => {
            setCurrentDrawing(null);
          },
        });
        return acc;
      },
      []
    );

    creditDrawings.push({
      millis: creditDrawings[creditDrawings.length - 1].millis + OFF_TIME,
      callback: () => setCurrentDrawing('END'),
    });

    const ids = creditDrawings.map((cd) => window.setTimeout(cd.callback, cd.millis));

    return () => {
      ids.forEach((id) => window.clearTimeout(id));
    };
  }, []);

  const didEnd = currentDrawing === 'END';
  const lightOn = currentDrawing !== null && !didEnd;

  return (
    <div className={'game-end_root'}>
      <div className={`game-end_lights ${lightOn || didEnd ? 'game-end_lights-on' : 'game-end_lights-off'}`} />
      <div className={'game-end_emoji'} />
      <Rat variant={'camera'} absolutePosition={{ bottom: 100, left: 0 }} />
      <Title title={'picks of the game'} />
      {didEnd ? (
        <div className={'game-end_body'}>
          <Title title={'Play again!'} style={{ marginRight: 200, marginTop: 100 }} />
        </div>
      ) : (
        lightOn && (
          <div className={'game-end_body'}>
            <div className={'game-end_frame'}>
              <DrawingAlt
                strokes={currentDrawing.strokes}
                tapeColor={G.colorPalettes[currentDrawing.playerID].canvas}
                bounds={currentDrawing.bounds}
              />
            </div>
            <div className={'game-end_player-part'}>
              <PlayerPart index={0} isVisible={true} player={roundWinnerEntry} />
              <SentencePuzzle
                sentence={cardSentences[currentDrawing.round].content}
                word={currentDrawing.word ?? ''}
                isVisibleWord={true}
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};
