import React, { useRef } from 'react';
import './HowToWin.css';
import { Rat } from '../../components/Rat';
import { Title } from '../Title';
import audio from '../../assets/audio/how-to-win.mp3';
import {
  getServerTimePassedMs,
  useMillisReachedFromServer,
  useServerAudio,
} from '@magicyard/blanksy-shared/hooks/IntervalHooks';
import { useGameObject } from '../../store/GameContext';
import { Phases } from '@magicyard/blanksy-game/src/Game';
import { DISABLE_AUDIO } from '../../AudioManager';

const FINAL_STEP = 4;
const stepToTime = (step: number) => {
  let timeAcc = 0;
  if (step >= 1) {
    timeAcc += 2500;
  }
  if (step >= 2) {
    timeAcc += 4000;
  }
  if (step >= 3) {
    timeAcc += 5000;
  }
  if (step >= 4) {
    timeAcc += 4000;
  }
  return timeAcc;
};

export const HowToWin = () => {
  const { G, ctx } = useGameObject();

  const [step, setStep] = React.useState(0);

  const sound = useRef(DISABLE_AUDIO ? null : new Audio(audio));
  useServerAudio({
    audio: sound.current,
    phaseStartTime: G.phaseStartTime,
    phase: ctx.phase as Phases,
    isTransition: false,
  });

  const x: Record<number, () => void> = {};
  for (let i = 0; i <= FINAL_STEP; i++) {
    x[stepToTime(i)] = () => setStep(i + 1);
  }
  useMillisReachedFromServer({
    serverTimePassedMs: getServerTimePassedMs(G.phaseStartTime, ctx.phase as Phases, false),
    millisWithCallback: x,
  });

  return (
    <div className={'how-to-win_root'}>
      {step >= 1 && <Title title={'HOW TO WIN'} className={'how-to-win_title'} />}
      {step >= 2 && (
        <div className={'how-to-win_funny-container'}>
          <div className={'how-to-win_anchor'}>
            <div className={'how-to-win_title-funny how-to-win_icon-title-appear-anim'}>
              <svg viewBox="0 0 100 100">
                <path id="textCurvePath" fill="none" stroke="transparent" d="m10,45c53,-38 88,7 88,10" />
                <path id="textCurvePath2" fill="none" stroke="transparent" d="m13,45c53,-38 88,7 88,10" />
                <text>
                  <textPath fill={'#a9e4e4'} href="#textCurvePath">
                    Be funny
                  </textPath>
                  <textPath fill={'black'} href="#textCurvePath2">
                    Be funny
                  </textPath>
                </text>
              </svg>
            </div>
            <div className={'how-to-win_emoji-funny how-to-win_icon-appear-anim'} />
            <div className={'how-to-win_part-funny-subtitle how-to-win_icon-subtitle-appear-anim'}>
              Funniest gets +15
            </div>
          </div>
        </div>
      )}
      {step >= 3 && (
        <div className={'how-to-win_nerd-container'}>
          <div className={'how-to-win_anchor'}>
            <div className={'how-to-win_title-nerd how-to-win_icon-title-appear-anim'}>
              <svg viewBox="0 0 110 110">
                <text>
                  <textPath fill={'#e4fb9f'} href="#textCurvePath">
                    guess well
                  </textPath>
                  <textPath fill={'black'} href="#textCurvePath2">
                    guess well
                  </textPath>
                </text>
              </svg>
            </div>
            <div className={'how-to-win_emoji-nerd how-to-win_icon-appear-anim'} />
            <div className={'how-to-win_part-nerd-subtitle how-to-win_icon-subtitle-appear-anim'}>
              {'Correct guesses +5 \n first to guess +5'}
            </div>
          </div>
        </div>
      )}
      {step >= 4 && (
        <div className={'how-to-win_artist-container'}>
          <div className={'how-to-win_anchor'}>
            <div className={'how-to-win_title-artist how-to-win_icon-title-appear-anim'}>
              <svg viewBox="0 0 110 110">
                <text>
                  <textPath fill={'#edbaf8'} href="#textCurvePath">
                    Draw well
                  </textPath>
                  <textPath fill={'black'} href="#textCurvePath2">
                    draw well
                  </textPath>
                </text>
              </svg>
            </div>
            <div className={'how-to-win_emoji-artist how-to-win_icon-appear-anim'} />
            <div className={'how-to-win_part-artist-subtitle how-to-win_icon-subtitle-appear-anim'}>
              {'your drawing guessed +10 \n Every other guess +2'}
            </div>
          </div>
        </div>
      )}
      <Rat absolutePosition={{ left: '6%', bottom: 100 }} variant={'announcer'} mirror={true} />
    </div>
  );
};
