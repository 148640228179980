import { ActivePlayersArg, Ctx, PlayerID } from 'boardgame.io';
import { RandomAPI } from 'boardgame.io/dist/types/src/plugins/random/random';
import { EventsAPI } from 'boardgame.io/dist/types/src/plugins/events/events';
import MovesUtil from './utils/moves.util';
import { PlayingCard } from './utils/assets/playingCards';

export interface EventsAPIGuaranteed extends EventsAPI {
  endGame(gameover?: any): void;

  endPhase(): void;

  endStage(): void;

  endTurn(arg?: { next: PlayerID }): void;

  pass(arg?: { remove: true }): void;

  setActivePlayers(arg: ActivePlayersArg): void;

  setPhase(newPhase: string): void;

  setStage(newStage: string): void;
}

export interface CtxWithApi extends Ctx {
  events: EventsAPIGuaranteed;
  random: RandomAPI;
  playerID: PlayerID; // Assuming playerId is always present maybe wrong
}

export type WordForPlayer = {
  [key: string]: string | null;
};

export type Guess = {
  playerID: PlayerID;
  score: number;
  word: string;
  time?: number;
  reason: GuessReason;
};

export type GuessReason = 'GUESS_FIRST' | 'GUESS_REGULAR' | 'DRAWER_FIRST' | 'DRAWER_REGULAR';

/*export type Player = {
  score: number;
  id: string;
};*/

// export type GameState = {
//   correctWord: string;
//   guesses: Array<Guess>;
//   winner: string;
//   players: { [id: string]: Player };
// };

export interface Point {
  x: number;
  y: number;
}

export type Stroke = {
  points: Array<Point>;
  color: string;
  width: number;
};

export type Drawing = {
  strokes: Stroke[];
  playerID: string;
  word: string | null;
  bounds: DrawingBound;
  didClear?: boolean;
};

export type DrawingBound = { maxX: number; maxY: number };

export type BoardPropTypes = G & {
  ctx: CtxWithApi;
  events: unknown;
  moves: { [key in keyof typeof MovesUtil]: (...arg: any) => void };
  playerID: PlayerID;
};

export interface IPlayer {
  id: PlayerID;
  name: string;
  avatarUrl: string;
  active: boolean;
}

export interface Palette {
  name: string;
  colors: string[];
  light: string;
  dark: string;
}

export const tapeColors = ['blue' as const, 'orange' as const, 'red' as const, 'yellow' as const, 'green' as const];
export type TapeColor = (typeof tapeColors)[0];

export interface G {
  didGameEnd: boolean;
  isTransition: boolean;
  score: number[];
  guesses: { [round: number]: Guess[] };
  phaseStartTime: number;

  currentRoundNumber: number;
  wordForPlayer: WordForPlayer;

  roundImageLogs: {
    round: number;
    strokes: Stroke[];
    drawer: string;
    word: string;
    sentence: string;
  }[];
  players: IPlayer[];

  cardSentences: PlayingCard[];

  roundDrawings: {
    [roundNumber: string]: {
      [playerId: PlayerID]: Drawing | undefined;
    };
  };
  // TODO rename
  ratingVoters: {
    [key: string]: PlayerID;
  };
  colorPalettes: {
    [key: string]: ColorPalette;
  };

  scoreDeltas: number[];
  topVoted: { [round: number]: string[] };

  shouldForceSubmit: boolean;

  shouldClearDrawingStorage: Record<string, boolean>;
}

export interface ColorPalette {
  palette: Palette;
  playerID: string;
  canvas: TapeColor;
}

export interface Palette {
  name: string;
  colors: Array<string>;
  light: string;
  dark: string;
}
