import React, { useCallback } from 'react';
import { useGameObject } from '../../../store/GameContext';
import { ProgressBarDrain } from '../../../components/Progress/ProgressBarDrain';
import { Phases } from '@magicyard/blanksy-game/src/Game';
import './DisplayActiveDrawingSimultaneous.css';
import { StageRoot } from '../StageRoot';
import { PlayersPart } from '../../PlayersPart';

const PAGE_INFO = {
  dictionary: {
    title: 'Time to Draw!',
    secondary: 'Get point when people guess your word',
  },
};

const DisplayActiveDrawingSimultaneous = () => {
  const { G, ctx } = useGameObject();
  const { players, roundDrawings, currentRoundNumber } = G;

  const isConfirmedClass = useCallback(
    (playerId) => {
      return roundDrawings[currentRoundNumber][playerId] !== undefined;
    },
    [roundDrawings, currentRoundNumber]
  );

  return (
    <>
      <StageRoot title={PAGE_INFO.dictionary.title} sentence={G.cardSentences[G.currentRoundNumber].content}>
        <PlayersPart players={players} isVisible={isConfirmedClass} />
      </StageRoot>
      <ProgressBarDrain phaseStartTime={G.phaseStartTime} phase={ctx.phase as Phases} />
    </>
  );
};

export default DisplayActiveDrawingSimultaneous;
