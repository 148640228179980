import React, { useEffect } from 'react';
import GameManager from '../GameManager/GameManager';
import GameContext from '../store/GameContext';

import { BoardPropsExtended } from '@magicyard/blanksy-game/src/Game';
import { updateAnalyticsData } from '@magicyard/shared/src/analytics';
import { Display } from '@magicyard/shared/platform/lib/api';

const Board = (props: BoardPropsExtended & { display: Display }) => {
  const { matchID, display, G } = props;

  useEffect(() => {
    updateAnalyticsData({ matchId: matchID });
  }, [matchID]);

  return (
    <GameContext.Provider value={props}>
      <GameManager display={display} />
    </GameContext.Provider>
  );
};

export default Board;
