import React from 'react';
import { Rat } from '../Rat';
import './TransitionContainer.css';

export interface TransitionContainerProps {
  titleImageUrl: string;
  attentionTitle: string;
  attentionBody: string;
}

export const TransitionContainer = ({ titleImageUrl, attentionTitle, attentionBody }: TransitionContainerProps) => {
  return (
    <div className={'transition-container_root'}>
      <div className={'transition-container_title-image'} style={{ backgroundImage: `url(${titleImageUrl})` }} />
      <div className={'transition-container_attention'} />
      <div className={'transition-container_attention-text-container'}>
        <div className={'transition-container_attention-text-container-body'}>
          <div className={'transition-container_attention-title'}>{attentionTitle}</div>
          <div className={'transition-container_attention-body'}>{attentionBody}</div>
        </div>
      </div>
      <Rat absolutePosition={{ right: 200, bottom: 100 }} variant={'spray'} />
    </div>
  );
};
