import React from 'react';
import canvasBlue from '../../assets/canvas/canvas-blue.webp';
import canvasOrange from '../../assets/canvas/canvas-orange.webp';
import canvasRed from '../../assets/canvas/canvas-red.webp';
import canvasYellow from '../../assets/canvas/canvas-red.webp';
import canvasGreen from '../../assets/canvas/canvas-green.webp';
import { assertNever } from '@magicyard/utils/typeUtils';
import { useGameObject } from '../../store/GameContext';
import { PlayerID } from 'boardgame.io';
export const DrawingContainer = ({
  amountOfDrawings,
  playerID,
  children,
}: {
  amountOfDrawings: number;
  playerID: PlayerID;
  children: React.ReactNode;
}) => {
  const { G } = useGameObject();
  return (
    <div
      style={{
        ...getDrawingAltWrapperSize(amountOfDrawings),
        backgroundImage: `url(${tapeColorToImageUrl(G.colorPalettes[playerID].canvas)})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {children}
    </div>
  );
};

const tapeColorToImageUrl = (tapeColor: 'blue' | 'orange' | 'red' | 'yellow' | 'green') => {
  switch (tapeColor) {
    case 'blue':
      return canvasBlue;
    case 'orange':
      return canvasOrange;
    case 'red':
      return canvasRed;
    case 'yellow':
      return canvasYellow;
    case 'green':
      return canvasGreen;
    default:
      assertNever(tapeColor);
  }
};

const getDrawingAltWrapperSize = (drawCount: number) => {
  const heightRatio = 50 / 32;
  if (drawCount < 4) {
    return { height: 500, width: 320 };
  }
  // 65 will reduce one width of a drawing for every new drawing added
  const width = 320 - (drawCount - 4) * 40;
  return {
    height: heightRatio * width,
    width: width,
  };
};
