import { Ctx } from 'boardgame.io';
import { Phases } from '../Game';
import { CtxWithApi, G } from '../Types';
import { MAX_ROUNDS } from '../Config';

const endGameIf = (G: G, ctx: Ctx): void | { winner: number } => {
  if (G.didGameEnd) {
    let draw = false;
    let winner = 0;
    for (let i = 1; i < ctx.numPlayers - 1; i++) {
      if (G.score[winner] < G.score[i]) {
        winner = i;
        draw = false;
      } else if (G.score[winner] === G.score[i]) {
        draw = true;
      }
    }
    console.log('todo handle draw!');
    // if (!draw) {
    return { winner: winner };
    // }
  }
};

interface Guess {
  playerID: string;
  score: number;
}

const calculateScore = (G: G, ctx: Ctx): void => {
  const scoreDeltas = Array<number>(ctx.numPlayers).fill(0);

  G.guesses[G.currentRoundNumber].forEach(({ playerID, score }: Guess) => {
    if (score > 0) {
      scoreDeltas[+playerID] += score;
    }
  });

  G.scoreDeltas = scoreDeltas;
  G.score = G.score.map((oldScore, playerID) => oldScore + scoreDeltas[playerID]);
};

const calculateVotingScore = (G: G, ctx: Ctx): void => {
  const scoreDeltas = Array(ctx.numPlayers).fill(0);

  const { topVoted } = Object.values(G.ratingVoters).reduce<{
    maxVotes: number;
    topVoted: Array<string>;
    votesPerPlayer: { [key: string]: number };
  }>(
    (acc, voteValue) => {
      if (!acc.votesPerPlayer[voteValue]) {
        acc.votesPerPlayer[voteValue] = 0;
      }
      acc.votesPerPlayer[voteValue] += 1;

      if (acc.votesPerPlayer[voteValue] === acc.maxVotes) {
        acc.topVoted.push(voteValue);
      } else if (acc.votesPerPlayer[voteValue] > acc.maxVotes) {
        acc.topVoted = [voteValue];
        acc.maxVotes = acc.votesPerPlayer[voteValue];
      }

      return acc;
    },
    {
      maxVotes: 0,
      topVoted: [],
      votesPerPlayer: {},
    }
  );

  const pointsForMostVotes = 12;
  topVoted.forEach((playerId) => {
    scoreDeltas[+playerId] += pointsForMostVotes;
  });

  G.topVoted[G.currentRoundNumber] = topVoted;
  G.scoreDeltas = scoreDeltas;
  G.score = G.score.map((oldScore, playerID) => oldScore + scoreDeltas[playerID]);
};

const BoardUtil = {
  endGameIf,
  calculateScore,
  calculateVotingScore,
};

export default BoardUtil;
