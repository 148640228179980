import {
  CombinedYard,
  connectToSocket,
  Display,
  DisplayDto,
  endGame,
  getYardAfterMutation,
  JoinedQueue,
  onGameEndedDisplay,
  onGameStartedDisplay,
  onGameStartFailure,
  onGameStarting,
  onJoinedQueue,
  onJoinedYard,
  onLeaveQueue,
  onQueueUpdated,
  onReactionReceived,
  onYardUpdated,
  QueueUpdated,
  updateYard,
  wasInvitedToNewYard,
  YardDto,
  yardDtoToYardWithGameUnsafe,
  yardDtoToYard,
  yardWithQueueToYardWithGame,
  onNavigationCommandReceived,
} from '../lib/api';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { NavigationStateInternal, StateConfigInternal, Communication } from './usePlatformDisplayTypes';
import { assertNever } from '@magicyard/utils/typeUtils';
import { App } from '@capacitor/app';
import { ConnectionState, getIsReconnecting } from './usePlatformControllerTypes';
import { getOrCreateDisplay } from '../lib/display';

interface Query {
  displayId: string | null;
}

type SetNavState<P> = Dispatch<SetStateAction<NavigationStateInternal<P>>>;

export const usePlatformDisplayInternal = <T, P>(
  stateConfig: StateConfigInternal<T, P>,
  initialQuery: Query,
  gameId?: string
) => {
  const [connectionState, setConnectionState] = useState<ConnectionState | null>(null);
  const [navState, setNavState] = useState<NavigationStateInternal<P>>({ navigation: 'initial' });

  useEffect(() => {
    handleConnectDisplay(setNavState, setConnectionState, initialQuery.displayId, gameId).catch(console.log);
  }, []);

  useSyncDisplay(navState, setNavState, setConnectionState, gameId);

  useHandleJoinYard(setNavState);
  useHandleYardUpdated(setNavState);
  useHandleGameStarting(setNavState);
  useHandleGameStarted(setNavState);
  useHandleGameEnded(setNavState);
  useHandleGameStartFail(setNavState);
  useHandleJoinedQueue(setNavState);
  useHandleQueueUpdated(setNavState);
  useHandleLeaveQueue(setNavState);
  const updateGameId = useCallback((gameId: string | null) => {
    // this will fire "YardUpdated"
    updateYard({ game_id: gameId });
  }, []);
  const comms: Communication = {
    receiveReaction: onReactionReceived,
    receiveNavigationCommand: onNavigationCommandReceived,
  };

  switch (navState.navigation) {
    case 'initial':
      return stateConfig.onLoading();
    case 'game_selection':
      return stateConfig.onGameSelection({
        yard: navState.yard,
        display: navState.display,
        setGameId: updateGameId,
        communication: comms,
        isReconnecting: getIsReconnecting(connectionState),
      });
    case 'yard_display':
      return stateConfig.onYardAndDisplay({
        yard: navState.yard,
        display: navState.display,
        setGameId: updateGameId,
        communication: comms,
        isReconnecting: getIsReconnecting(connectionState),
      });
    case 'queue':
      return stateConfig.onQueue({
        yard: navState.yard,
        display: navState.display,
        communication: comms,
        isReconnecting: getIsReconnecting(connectionState),
      });
    case 'loading_game':
      return stateConfig.onLoadingGame({
        yard: navState.yard,
        display: navState.display,
        communication: comms,
        isReconnecting: getIsReconnecting(connectionState),
      });
    case 'game':
      return stateConfig.onGame({
        yard: navState.yard,
        gameStartArgs: navState.gameStartArgs,
        display: navState.display,
        onCloseGame: endGame,
        communication: comms,
        isReconnecting: getIsReconnecting(connectionState),
      });
    default:
      return assertNever(navState);
  }
};

const useEvent = <T>(event: (handler: (data: T) => void) => () => void, handler: (data: T) => void) => {
  useEffect(() => {
    const off = event(handler);
    return () => {
      off();
    };
  }, [event, handler]);
};

const handleConnectDisplay = async <P>(
  setNavState: SetNavState<P>,
  setConnectionState: (val: ConnectionState) => void,
  queryDisplayId: string | null,
  gameId?: string
) => {
  const display = await getOrCreateDisplay(queryDisplayId, gameId);
  setNavState(displayDtoToNavigationState(display));
  connectToSocket({
    socketData: {
      type: 'display',
      id: display.id,
    },
    handleConnect: async () => {
      const nextDisplay = await getOrCreateDisplay(display.id, gameId);
      setNavState(displayDtoToNavigationState(nextDisplay));
      setConnectionState('connected');
    },
    handleReconnecting: () => {
      setConnectionState('reconnecting');
    },
  });
};

const useHandleJoinYard = <P>(setNavState: SetNavState<P>) => {
  const handleJoinedYard = useCallback(
    (yardDto: YardDto) => {
      const yard = yardDtoToYard(yardDto);
      setNavState((oldNavState) => {
        switch (oldNavState.navigation) {
          case 'initial':
            throw new Error('impossible to load yard before display');
          case 'queue':
          case 'yard_display':
          case 'loading_game':
          case 'game':
          case 'game_selection':
            return handleYardReceived(yard, oldNavState.display);
          default:
            return assertNever(oldNavState);
        }
      });
    },
    [setNavState]
  );
  useEvent(onJoinedYard, handleJoinedYard);
};

const handleYardReceived = <P>(yard: CombinedYard, display: Display): NavigationStateInternal<P> => {
  switch (yard.type) {
    case 'basic':
      return {
        navigation: 'game_selection',
        display: display,
        yard: yard,
      };
    case 'withGame':
      if (yard.display_url !== null) {
        throw new Error('Spectator not supported');
      }
      return {
        navigation: 'yard_display',
        display: display,
        yard: yard,
      };
    case 'withQueue': {
      return {
        navigation: 'queue',
        display: display,
        yard: yard,
      };
    }
    default:
      assertNever(yard);
  }
};

const useHandleYardUpdated = <P>(setNavState: SetNavState<P>) => {
  const handleYardUpdated = useCallback(
    (yardDto: Partial<YardDto>) => {
      setNavState((oldNavState) => {
        switch (oldNavState.navigation) {
          case 'initial':
            throw new Error("Yard updated before it's available available");
          case 'queue': {
            const newYard = getYardAfterMutation(yardDto, oldNavState.yard);
            if (wasInvitedToNewYard(newYard, oldNavState.yard)) {
              return handleYardReceived(newYard, oldNavState.display);
            }
            switch (newYard.type) {
              case 'basic':
                throw new Error('Impossible to have a yard without a game id');
              case 'withGame':
                throw new Error('Impossible state, if we disconnected we first must be hydrated');
              case 'withQueue':
                return {
                  ...oldNavState,
                  yard: newYard,
                };
              default:
                return assertNever(newYard);
            }
          }
          case 'yard_display':
          case 'loading_game':
          case 'game': {
            const newYard = getYardAfterMutation(yardDto, oldNavState.yard);
            if (wasInvitedToNewYard(newYard, oldNavState.yard)) {
              return handleYardReceived(newYard, oldNavState.display);
            }
            switch (newYard.type) {
              case 'basic':
                return {
                  ...oldNavState,
                  navigation: 'game_selection',
                  yard: newYard,
                };
              case 'withQueue':
                throw new Error('Impossible state, if we disconnected we first must be hydrated');
              case 'withGame':
                // TODO figure out if still in old state or back to yard_display
                return {
                  ...oldNavState,
                  yard: newYard,
                };
              default:
                return assertNever(newYard);
            }
          }
          case 'game_selection': {
            const newYard = getYardAfterMutation(yardDto, oldNavState.yard);
            if (wasInvitedToNewYard(newYard, oldNavState.yard)) {
              return handleYardReceived(newYard, oldNavState.display);
            }
            switch (newYard.type) {
              case 'basic':
                return {
                  ...oldNavState,
                  yard: newYard,
                };
              case 'withGame':
                return {
                  ...oldNavState,
                  navigation: 'yard_display',
                  yard: newYard,
                };
              case 'withQueue':
                throw new Error('Impossible state, if we disconnected we first must be hydrated');
              default:
                return assertNever(newYard);
            }
          }
          default:
            return assertNever(oldNavState);
        }
      });
    },
    [setNavState]
  );

  useEvent(onYardUpdated, handleYardUpdated);
};

const useHandleJoinedQueue = <P>(setNavState: SetNavState<P>) => {
  const handleJoinedQueue = useCallback(
    (queue: JoinedQueue) => {
      setNavState((oldNavState) => {
        switch (oldNavState.navigation) {
          case 'initial':
          case 'loading_game':
          case 'queue':
          case 'game':
          case 'game_selection':
            console.log('Impossible to join queue when in: ' + oldNavState.navigation);
            return oldNavState;
          case 'yard_display':
            return {
              navigation: 'queue',
              yard: {
                ...oldNavState.yard,
                type: 'withQueue',
                queue: { id: queue.id, yards: queue.yards.map(yardDtoToYard) },
              },
              display: oldNavState.display,
            };
          default:
            assertNever(oldNavState);
        }
      });
    },
    [setNavState]
  );

  useEvent(onJoinedQueue, handleJoinedQueue);
};
const useHandleQueueUpdated = <P>(setNavState: SetNavState<P>) => {
  const handleQueueUpdated = useCallback(
    (updates: QueueUpdated) => {
      setNavState((oldNavState) => {
        switch (oldNavState.navigation) {
          case 'initial':
          case 'loading_game':
          case 'yard_display':
          case 'game_selection':
          case 'game':
            console.log('Impossible to update queue when in: ' + oldNavState.navigation);
            return oldNavState;
          case 'queue':
            return {
              ...oldNavState,
              yard: {
                ...oldNavState.yard,
                queue: {
                  id: oldNavState.yard.queue.id,
                  yards: updates.yards.map(yardDtoToYard),
                },
              },
            };
          default:
            assertNever(oldNavState);
        }
      });
    },
    [setNavState]
  );

  useEvent(onQueueUpdated, handleQueueUpdated);
};

const useHandleLeaveQueue = <P>(setNavState: SetNavState<P>) => {
  const handleLeaveQueue = useCallback(() => {
    setNavState((oldNavState) => {
      switch (oldNavState.navigation) {
        case 'initial':
        case 'loading_game':
        case 'yard_display':
        case 'game':
        case 'game_selection':
          console.log('Impossible to update queue when in: ' + oldNavState.navigation);
          return oldNavState;
        case 'queue':
          return handleYardReceived(oldNavState.yard, oldNavState.display);
        default:
          assertNever(oldNavState);
      }
    });
  }, [setNavState]);
  useEvent(onLeaveQueue, handleLeaveQueue);
};

const useHandleGameStarting = <P>(setNavState: SetNavState<P>) => {
  const handle = useCallback(() => {
    setNavState((oldNavState) => {
      switch (oldNavState.navigation) {
        case 'game_selection':
        case 'initial':
          throw new Error('Impossible to start game before yard and display');
        case 'queue':
        case 'loading_game':
        case 'game':
        case 'yard_display':
          return {
            navigation: 'loading_game',
            yard: yardWithQueueToYardWithGame(oldNavState.yard),
            display: oldNavState.display,
          };
        default:
          assertNever(oldNavState);
      }
    });
  }, [setNavState]);
  useEvent(onGameStarting, handle);
};
const useHandleGameStarted = <P>(setNavState: SetNavState<P>) => {
  const handle = useCallback(
    (displayDto: DisplayDto) => {
      setNavState(displayDtoToNavigationState(displayDto));
    },
    [setNavState]
  );
  useEvent(onGameStartedDisplay, handle);
};

const displayDtoToNavigationState = <P>(displayDto: DisplayDto): NavigationStateInternal<P> => {
  const display: Display = { code: displayDto.code, id: displayDto.id };

  if (displayDto.yard.game_starting) {
    return {
      navigation: 'loading_game',
      display: display,
      yard: yardDtoToYardWithGameUnsafe(displayDto.yard),
    };
  }
  if (displayDto.game_start_args !== undefined && displayDto.game_start_args !== null) {
    return {
      navigation: 'game',
      display: display,
      gameStartArgs: displayDto.game_start_args as P,
      yard: yardDtoToYardWithGameUnsafe(displayDto.yard),
    };
  }
  const yard = yardDtoToYard(displayDto.yard);

  return handleYardReceived(yard, display);
};

const useHandleGameEnded = <P>(setNavState: SetNavState<P>) => {
  const handle = useCallback(
    (display: DisplayDto) => {
      setNavState(displayDtoToNavigationState(display));
    },
    [setNavState]
  );
  useEvent(onGameEndedDisplay, handle);
};

const useHandleGameStartFail = <P>(setNavState: SetNavState<P>) => {
  const handle = useCallback(
    (e) => {
      setNavState((oldNavState) => {
        switch (oldNavState.navigation) {
          case 'initial':
          case 'game_selection':
            throw new Error('Game failed to start before a game can be started');
          case 'game':
            throw new Error('Game failed to start while game already running?');
          case 'queue':
          case 'loading_game':
          case 'yard_display':
            return {
              navigation: 'yard_display',
              yard: yardWithQueueToYardWithGame(oldNavState.yard),
              display: oldNavState.display,
            };
          default:
            assertNever(oldNavState);
        }
      });
      // Todo temp
      alert(e.reason);
    },
    [setNavState]
  );
  useEvent(onGameStartFailure, handle);
};

const useSyncDisplay = <P>(
  navState: NavigationStateInternal<P>,
  setNavState: SetNavState<P>,
  setConnectionState: (val: ConnectionState) => void,
  gameId: string | undefined
) => {
  useEffect(() => {
    const plugin = App.addListener('resume', async () => {
      switch (navState.navigation) {
        case 'initial': {
          const display = await getOrCreateDisplay(null, gameId);
          setNavState(displayDtoToNavigationState(display));
          break;
        }
        case 'yard_display':
        case 'queue':
        case 'loading_game':
        case 'game':
        case 'game_selection': {
          const nextDisplay = await getOrCreateDisplay(navState.display.id, gameId);
          setNavState(displayDtoToNavigationState(nextDisplay));
          break;
        }
        default:
          assertNever(navState);
      }
    });
    return () => {
      plugin.then((x) => x.remove());
    };
  }, [gameId, navState, setNavState]);
};
