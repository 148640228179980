import React, { useMemo } from 'react';
import { Phases } from '@magicyard/blanksy-game/src/Game';
import './ProgressBarDrain.css';
import { transitionTimeForPhasesInMs, waitTimeForPhaseInMs } from '@magicyard/blanksy-game/src/Config';

export const ProgressBarDrain = ({ phase, phaseStartTime }: { phase: Phases; phaseStartTime: number }) => {
  const time = useMemo(
    () => phaseStartTime + waitTimeForPhaseInMs[phase] + (transitionTimeForPhasesInMs[phase] ?? 0) - Date.now(),
    []
  );
  const x = new Array(50).fill(0).map((x, index) => {
    return {
      transform: `rotate(${index * 10}deg`,
      animationDelay: `${Math.round(Math.random() * 1000)}ms`,
    };
  });
  return (
    <div className={'progress-bar-drain_root'}>
      <div className={'progress-bar-drain_bomb-body'}>
        <div className={'progress-bar-drain_bomb-body-shade'} />
      </div>
      <div
        className={'progress-bar-drain_bomb-wire'}
        style={{
          animationDuration: waitTimeForPhaseInMs[phase] + 'ms',
          animationDelay: -(waitTimeForPhaseInMs[phase] - time) + 'ms',
        }}
      >
        <div className={'progress-bar-drain_bomb-wire-shade'}></div>

        <div
          className={'progress-bar-drain_spark'}
          style={{
            animationDuration: waitTimeForPhaseInMs[phase] + 'ms',
            animationDelay: -(waitTimeForPhaseInMs[phase] - time) + 'ms',
          }}
        >
          {x.map((d, idx) => (
            <div className={'progress-bar-drain_particle'} style={d} key={idx}>
              <div className={'progress-bar-drain_particle-body'} style={{ animationDelay: d.animationDelay }} />
            </div>
          ))}
          <div className={'progress-bar-drain_spark-animation-container'}>
            <div className={'progress-bar-drain_spark-shade'} />
          </div>
          <div className={'progress-bar-drain_aura'} />
        </div>
      </div>
    </div>
  );
};
