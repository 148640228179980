import React from 'react';
import { IPlayer } from '@magicyard/blanksy-game/src/Types';

export interface PlayerPartProps {
  index: number;
  isVisible: boolean;
  player: IPlayer;
  containerClassName?: string;
}

export const PlayerPart = ({ index, isVisible, player, containerClassName }: PlayerPartProps) => {
  return (
    <div
      className={`avatar avatar--xl mx-3 ${index % 2 ? 'mt-5' : ''} ${containerClassName ?? ''}`}
      style={{ textAlign: 'center' }}
    >
      <div className={`transition--fadeIn ${isVisible ? 'transition--active' : ''}`}>
        <img alt="user-avatar" src={player.avatarUrl} />
        <div className={'player-part_text'}>{player.name}</div>
      </div>
    </div>
  );
};
