import React from 'react';
import { useGameObject } from '../../../store/GameContext';
import { ProgressBarDrain } from '../../../components/Progress/ProgressBarDrain';
import { Phases } from '@magicyard/blanksy-game/src/Game';
import { StageRoot } from '../StageRoot';
import { DrawingsPart } from '../DrawingsPart';

const DisplayDrawingVote = () => {
  const { G, ctx } = useGameObject();
  const { roundDrawings, currentRoundNumber, players } = G;

  const drawings = players.map((player) => {
    const drawing = roundDrawings[currentRoundNumber][player.id];
    return {
      playerID: player.id,
      strokes: drawing !== undefined ? drawing.strokes : [],
      word: drawing !== undefined ? drawing.word : '',
      bounds: drawing !== undefined ? drawing.bounds : { maxX: 0, maxY: 0 },
    };
  });

  return (
    <>
      <StageRoot title={'Vote for the funniest'} sentence={G.cardSentences[G.currentRoundNumber].content}>
        <DrawingsPart drawings={drawings} showWord={true} showHints={false} />
      </StageRoot>
      <ProgressBarDrain phase={ctx.phase as Phases} phaseStartTime={G.phaseStartTime} />
    </>
  );
};

export default DisplayDrawingVote;
