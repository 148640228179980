import React, { useCallback } from 'react';
import { useGameObject } from '@magicyard/blanksy-display/src/store/GameContext';
import { ProgressBarDrain } from '../../components/Progress/ProgressBarDrain';
import { Phases } from '@magicyard/blanksy-game/src/Game';
import './PreGameCompleteSentence.css';
import { StageRoot } from './StageRoot';
import { PlayersPart } from '../PlayersPart';

const PAGE_INFO = {
  dictionary: {
    title: 'Complete the sentence',
    subtitle: 'You will need to draw it later...',
    info_title: 'Choose a word to complete the sentence',
    info_secondary: 'You will need to draw it later...',
  },
};

const PreGameCompleteSentence = () => {
  const { G, ctx } = useGameObject();

  const { players, wordForPlayer, phaseStartTime } = G;

  const isConfirmedClass = useCallback((playerId: string) => wordForPlayer[playerId] !== undefined, [wordForPlayer]);

  return (
    <>
      <StageRoot sentence={G.cardSentences[G.currentRoundNumber].content} title={PAGE_INFO.dictionary.title}>
        <PlayersPart players={players} isVisible={isConfirmedClass} />
      </StageRoot>
      <ProgressBarDrain phase={ctx.phase as Phases} phaseStartTime={phaseStartTime} />
    </>
  );
};

export default PreGameCompleteSentence;
