import { getMultiplayerMode } from '@magicyard/utils';
import { Blanksy, BoardPropsExtended } from '@magicyard/blanksy-game/src/Game';
import Board from './components/Board';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { usePlatformDisplay } from '@magicyard/shared/platform/hooks/usePlatformDisplay';
import { CombinedYard, Display } from '@magicyard/shared/platform/lib/api';
import { Background } from './components/Background/Background';
import './App.css';
import './index.css';
import { useFullscreen } from '@magicyard/shared/platform/hooks/useTryFullscreen';
import useForceScale from '@magicyard/shared/platform/hooks/useForceScale';
import { AppIntroAudio, AudioStagesMap, stopMusic } from './AudioManager';
import { useNativeFocus } from '@magicyard/shared/src/UseNativeFocus';
import { YardAndDisplay } from './YardAndDisplay';
import { initAnalytics, track, updateAnalyticsData } from '@magicyard/shared/src/analytics';
import { InitialLoading } from './InitialLoading';
import { QueueScreen } from './QueueScreen';
import { assertIsDefined } from '@magicyard/utils/typeUtils';
import { clearTimeout } from 'timers';
import { CONFIG } from '@magicyard/utils';
import { StateConfigExternal } from '@magicyard/shared/platform/hooks/usePlatformDisplayTypes';
import { MagicyardBgioClient } from '@magicyard/shared/src/MagicyardBgioClient';
import { CtxWithApi, G } from '@magicyard/blanksy-game/src/Types';

export interface AppProps {
  matchID: string;
}

export interface DisplayMainLoaderProps {
  yard: CombinedYard;
}

export const GameLoader = (props: DisplayMainLoaderProps) => {
  const { yard } = props;
  useEffect(() => {
    updateAnalyticsData({ yardId: yard.id });
    track('Game Loading Appeared');
  }, [yard]);

  return <InitialLoading />;
};
export const App: React.ReactNode = () => {
  useFullscreen();
  useForceScale('#root-blanksy');

  useEffect(() => {
    initAnalytics(CONFIG.MIXPANEL_API_KEY, 'Display', 'Standalone', 'blanksy');
    track('App Launched');
  }, []);

  const query = new URLSearchParams(window.location.search);
  // Force click on none native devices
  const [clickRequiredBeforeStart, setClickRequiredBeforeStart] = useState(query.get('version_name') === null);
  const [didInitialHardCodedLoadingFinish, setDidInitialHardCodedLoadingFinish] = useState(false);
  const [isFocus, setIsFocus] = useState(true);
  const isOnGame = useRef(false);
  const stateConfig = getStateConfig(
    clickRequiredBeforeStart,
    setClickRequiredBeforeStart,
    'controller-blanksy.mgy.gg'
  );
  const didPlayThroughIntro = useRef(false);
  const handleFocusUpdate = (isInFocus: boolean) => {
    if (isOnGame.current) {
      return;
    }
    setIsFocus(isInFocus);
  };

  useNativeFocus(
    () => handleFocusUpdate(true),
    () => handleFocusUpdate(false)
  );
  useEffect(() => {
    if (isOnGame.current) {
      return;
    }

    if (!clickRequiredBeforeStart && isFocus && didInitialHardCodedLoadingFinish) {
      if (AppIntroAudio !== null) {
        if (!didPlayThroughIntro.current) {
          AppIntroAudio.play();
          AppIntroAudio.onended = () => {
            assertIsDefined(AudioStagesMap.Initial);
            AudioStagesMap.Initial.volume = 0.5;
            AudioStagesMap.Initial.play();
            didPlayThroughIntro.current = true;
          };
        } else {
          assertIsDefined(AudioStagesMap.Initial);
          AudioStagesMap.Initial.volume = 0.5;
          AudioStagesMap.Initial.play();
        }
      }
    }
    return () => {
      if (AppIntroAudio !== null) {
        AppIntroAudio.onended = null;
        AppIntroAudio.pause();
        AudioStagesMap.Initial?.pause();
      }
    };
  }, [clickRequiredBeforeStart, didInitialHardCodedLoadingFinish, isFocus]);

  const child = usePlatformDisplay<
    {
      children: React.ReactNode;
    },
    { url: string }
  >(
    {
      ...stateConfig,
      onGame: (params) => {
        isOnGame.current = true;
        return stateConfig.onGame(params);
      },
    },
    { displayId: query.get('displayId') },
    'blanksy'
  );
  return (
    <>
      {child.children}
      <TempLoading onEnd={() => setDidInitialHardCodedLoadingFinish(true)} />
    </>
  );
};

const getStateConfig = (
  clickRequiredBeforeStart: boolean,
  setClickRequiredBeforeStart: (val: boolean) => void,
  url: string
): StateConfigExternal<{ children: React.ReactNode }, { url: string }> => ({
  onLoading() {
    return {
      children: (
        <Background camPosition={0}>
          <InitialLoading />
        </Background>
      ),
    };
  },
  onYardAndDisplay({ yard, display }) {
    return {
      children: (
        <Background
          camPosition={clickRequiredBeforeStart ? 0 : yard.controllers.length === 0 ? 1 : 0.7}
          renderPosition={clickRequiredBeforeStart ? undefined : 1}
          key={0}
        >
          {clickRequiredBeforeStart ? (
            <InitialLoading onClick={() => setClickRequiredBeforeStart(false)} />
          ) : (
            <YardAndDisplay
              url={url}
              yard={yard}
              display={display}
              onClick={() => setClickRequiredBeforeStart(false)}
              clickRequiredBeforeStart={clickRequiredBeforeStart}
            />
          )}
        </Background>
      ),
    };
  },
  onLoadingGame({ yard }) {
    return {
      children: (
        <Background camPosition={0}>
          <GameLoader yard={yard} />
        </Background>
      ),
    };
  },
  onQueue({ yard, display }) {
    return {
      children: (
        <Background camPosition={1.7}>
          <QueueScreen display={display} yard={yard} />
        </Background>
      ),
    };
  },
  onGame({ yard, gameStartArgs, display, onBack, communication }) {
    return {
      position: null as any,
      children: (
        <OnGame
          display={display}
          yard={yard}
          onBack={onBack}
          gameStartArgs={gameStartArgs}
          communication={communication}
        />
      ),
    };
  },
});
export const onYardAndDisplayExternal: StateConfigExternal<JSX.Element, any>['onYardAndDisplay'] = (params) => (
  <div className={'root-blanksy'}>
    {getStateConfig(false, () => undefined, 'magicyard.tv').onYardAndDisplay(params).children}
  </div>
);

export const onGameExternal: StateConfigExternal<JSX.Element, any>['onGame'] = (params) => (
  <div className={'root-blanksy'}>{getStateConfig(false, () => undefined, 'magicyard.tv').onGame(params).children}</div>
);

const OnGame: StateConfigExternal<JSX.Element, any>['onGame'] = ({ display, gameStartArgs }) => {
  const urlParams = new URLSearchParams(new URL(gameStartArgs.url).search);
  const matchID = urlParams.get('matchID') || process.env.REACT_APP_MATCH_ID || 'default';
  const serverURL = urlParams.get('serverURL') ?? undefined;

  useEffect(() => {
    track('Game Started');
  }, [matchID]);

  useEffect(() => {
    // Every time matchID changes
    return stopMusic;
  }, [matchID]);

  const clientOpts = useMemo(
    () => ({
      game: Blanksy,
      board: Board,
      debug: true,
      multiplayer: getMultiplayerMode(serverURL),
    }),
    [serverURL]
  );

  const boardProps = useMemo(
    () => ({
      matchID: matchID,
      playerID: '0',
      display: display,
    }),
    [display, matchID]
  );

  return (
    <MagicyardBgioClient<G, BoardPropsExtended & { display: Display }, CtxWithApi>
      clientOpts={clientOpts}
      boardProps={boardProps}
    />
  );
};

const TempLoading = (props: { onEnd: () => void }) => {
  const [show, setShow] = useState(true);
  const [render, setRender] = useState(true);
  useEffect(() => {
    const id = setTimeout(() => {
      setShow(false);
    }, 5000);

    return () => {
      clearTimeout(id);
    };
  }, []);

  if (!render) {
    return null;
  }
  return (
    <div
      style={{
        zIndex: 1000,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        opacity: show ? 1 : 0,
        transition: 'opacity 0.5s',
        color: 'white',
        background: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onTransitionEnd={() => {
        props.onEnd();
        setRender(false);
      }}
    >
      Loading...
    </div>
  );
};
