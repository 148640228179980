import { Phases } from './Game';

const ms = 1000;

export const _DEMO_TEMP_INITIAL_DELAY = 12 * ms;

export const SYNC_MS = 10 * ms;
export const CHOOSE_WORD_TIMEOUT_MS = 65 * ms;
export const DRAWING_TIMEOUT_MS = 65 * ms;
export const GUESSING_TIMEOUT_MS = 65 * ms;
export const VOTING_TIMEOUT_MS = 20 * ms;
export const TRANSITION_TIMEOUT_MS = 5 * ms;
export const HOW_TO_WIN_MS = 23 * ms;
export const HOW_TO_WIN_TRANSITION_MS = 26 * ms; // + _DEMO_TEMP_INITIAL_DELAY
export const SCORE_MS = 17 * ms;
export const WINNER_MS = 10 * ms;
export const GAME_END = 60 * ms;

export const MAX_ROUNDS = 3;

export const waitTimeForPhaseInMs: Record<Phases, number> = {
  Sync: SYNC_MS,
  Explainer: HOW_TO_WIN_MS,
  Initial: CHOOSE_WORD_TIMEOUT_MS,
  firstStage: DRAWING_TIMEOUT_MS,
  secondStage: GUESSING_TIMEOUT_MS,
  thirdStage: VOTING_TIMEOUT_MS,
  fourthStage: TRANSITION_TIMEOUT_MS,
  endRound: SCORE_MS,
  gameEnd: GAME_END,
};

export const transitionTimeForPhasesInMs: Record<Phases, number | null> = {
  Sync: null,
  Explainer: HOW_TO_WIN_TRANSITION_MS,
  Initial: TRANSITION_TIMEOUT_MS,
  firstStage: TRANSITION_TIMEOUT_MS,
  secondStage: TRANSITION_TIMEOUT_MS,
  thirdStage: TRANSITION_TIMEOUT_MS,
  fourthStage: null,
  endRound: null,
  gameEnd: WINNER_MS,
};
