import React from 'react';
import { Title } from './GameManager/Title';
import './InitialLoading.css';
import { Crown } from './components/Crown';

export const InitialLoading = ({ onClick }: { onClick?: () => void }) => {
  return (
    <div className={'initial-loading_root'} onClick={onClick}>
      <Crown variant={'dark'} className={'initial-loading_crown'} />
      <Title title={'Blanksy'} className={'initial-loading_title'} />
      <div className={'initial-loading_subtitle'}>The hilarious drawing game</div>
      {onClick !== undefined ? (
        <div style={{ color: '#06ae58', fontSize: 60, marginTop: 120 }}>click anywhere to start!</div>
      ) : (
        'Loading...'
      )}
    </div>
  );
};
