import React, { useEffect, useRef, useState } from 'react';
import { Ctx } from 'boardgame.io';
import { WrappedBoardProps } from 'boardgame.io/dist/types/src/client/react';
import { BoardProps, Client as makeClient } from 'boardgame.io/react';

type OptsType<G extends any, P extends BoardProps<G>, ContextWithPlugins extends Ctx> = Parameters<
  typeof makeClient<G, P, ContextWithPlugins>
>[0];

export const MagicyardBgioClient = <
  G extends any = any,
  P extends BoardProps<G> = BoardProps<G>,
  ContextWithPlugins extends Ctx = Ctx
>({
  clientOpts,
  boardProps,
}: {
  clientOpts: OptsType<G, P, ContextWithPlugins>;
  boardProps: WrappedBoardProps & Omit<P, keyof BoardProps<G>>;
}) => {
  const [BgioClient, setBgio] = useState(() => makeClient<G, P, ContextWithPlugins>(clientOpts));
  const firstUpdate = useRef(true);

  useEffect(() => {
    if (firstUpdate) {
      firstUpdate.current = false;
      return;
    }
    setBgio(makeClient<G, P, ContextWithPlugins>(clientOpts));
  }, [clientOpts]);

  console.log(boardProps);

  // TODO why this cast?
  return <BgioClient {...(boardProps as any)} />;
};
